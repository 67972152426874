import React, { FC, useEffect } from 'react'

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Drawer,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  SxProps,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'

import {
  Add,
  AssignmentTurnedIn,
  Healing,
  Pets,
  ReportGmailerrorredOutlined,
  Search as SearchIcon,
  Transgender,
  Tune,
} from '@mui/icons-material'

import {
  AdopterDto,
  AdopterDtoPreferenceSex,
  AdopterDtoPreferenceSterilizedOrNeutered,
  AdopterDtoProfileHasBeenAccepted,
  AdoptionAttemptDto,
  AdoptionAttemptDtoStatus,
  AnimalDtoSpecies,
  AnimalInsightsDtoType,
} from '../../interactors/gen/backendClient'
import { CircularProgressPanel } from '../common/CircularProgressPanel'

import { useMediaQuery } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { accountCanEdit } from '../../domain/Account/accountCanEdit'
import {
  getColorForAdopterProfileHasBeenAccepted,
  getReadableAdopterFieldLabel,
  getReadableAdopterPreferenceSex,
  getReadableAdopterPreferenceSterilized,
  getReadableAdopterProfileHasBeenAccepted,
} from '../../domain/Adopters/AccountDisplay'
import { searchAdopters } from '../../domain/Adopters/searchAdopters'
import { sortAdopters } from '../../domain/Adopters/sortAdopters'
import { getReadableSpecies } from '../../domain/Animal/AnimalDisplay'
import { useAccountsStore } from '../../store/AccountsStore'
import { useAdoptersStore } from '../../store/AdoptersStore'
import { useFetchOrRefreshAdoptersOnMount } from '../../store/useFetchOrRefreshAdoptersOnMount'
import { getAnimalProfilePhotoSrc } from '../../utils/S3-links'
import { getAdoptionAttemptStatus } from '../../utils/getAdoptionAttemptStatus'
import { isColorDark } from '../../utils/isColorDark'
import { setOpacity } from '../../utils/setOpacity'
import { sortAlphabetically } from '../../utils/sort'
import { HtmlTooltip } from '../Animals/AnimalsTableItem'
import { FeaturesList } from '../Association/Functionalities/FeaturesList'
import { PATHS } from '../PATHS'
import { AQuestion } from '../common/AQuestion'
import { FilterList } from '../common/FilterList'
import { FilterListItem } from '../common/FilterListItem'
import { InfoBox } from '../common/InfoBox'
import { blue, colors, spacingItem, theme } from '../theme'
import { AdopterListCard } from './AdopterListCard'

type Tabs = 'all' | 'new' | 'to-do' | 'waiting' | 'to-match' | 'settings'

export const AdoptersListScreen: FC = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [adopters, setAdopters] = React.useState<AdopterDto[]>([])
  const [selectedTab, setSelectedTab] = React.useState<Tabs>((queryParams.get('tab') as Tabs) || 'all')
  const [selectedSpecies, setSelectedSpecies] = React.useState<AnimalDtoSpecies[]>([])
  const [selectedSexs, setSelectedSexs] = React.useState<AdopterDtoPreferenceSex[]>([])
  const [selectedSterilisations, setSelectedSterilisations] = React.useState<
    AdopterDtoPreferenceSterilizedOrNeutered[]
  >([])
  const [selectedProfileHasBeenAccepted, setSelectedProfileHasBeenAccepted] = React.useState<
    AdopterDtoProfileHasBeenAccepted[]
  >([])
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const navigate = useNavigate()

  useFetchOrRefreshAdoptersOnMount()

  const adoptersStore = useAdoptersStore()
  const accountsStore = useAccountsStore()

  const association = accountsStore.connectedCustomer
  const hasTemplate = association?.templates && association.templates.length > 0 ? true : false
  const hasEmailTemplate = association?.emailTemplate !== undefined

  useEffect(() => {
    const isNotDeleted = (adopter: AdopterDto) => !adopter.isDeleted
    const hasAdoptionWithInsight = (adopter: AdopterDto) => {
      return adopter.adoptionAttempts?.some(
        (attempt: AdoptionAttemptDto) => attempt.insights && attempt.insights.length > 0
      )
    }

    switch (selectedTab) {
      case 'all':
        setAdopters(adoptersStore.adopters.filter(isNotDeleted))
        break
      case 'new':
        setAdopters(
          adoptersStore.adopters.filter(
            (adopter) =>
              isNotDeleted(adopter) && adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Waiting
          )
        )
        break
      case 'to-do':
        setAdopters(
          adoptersStore.adopters.filter((adopter) => isNotDeleted(adopter) && hasAdoptionWithInsight(adopter))
        )
        break
      case 'to-match':
        setAdopters(
          adoptersStore.adopters.filter(
            (adopter) =>
              isNotDeleted(adopter) &&
              adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted &&
              (adopter.adoptionAttempts?.length === 0 || adopter.adoptionAttempts === undefined)
          )
        )
        break
      case 'settings':
        break
      default:
        throw new Error(`selectedTab ${selectedTab} not supported`)
    }
  }, [adoptersStore.adopters, selectedTab])

  const handleTabChange = (_: React.SyntheticEvent, newValue: 'all' | 'new' | 'to-do' | 'to-match' | 'settings') => {
    navigate(`/suivi-adoptant?tab=${newValue}`)
    setSelectedTab(newValue)
  }

  const [searchQuery, setSearchQuery] = React.useState('')

  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    setSearchQuery(e.target.value)
  }

  const account = accountsStore.connectedAccount

  const species = accountsStore.connectedCustomer?.preferences.species

  const numberOfWaitingProfiles = adoptersStore.adopters.filter(
    (adopter) => adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Waiting
  ).length

  const profileHasBeenAcceptedCustomOrder = [
    AdopterDtoProfileHasBeenAccepted.FormToSent,
    AdopterDtoProfileHasBeenAccepted.Waiting,
    AdopterDtoProfileHasBeenAccepted.FormAccepted,
    AdopterDtoProfileHasBeenAccepted.Accepted,
    AdopterDtoProfileHasBeenAccepted.FormSent,
    AdopterDtoProfileHasBeenAccepted.FormRefused,
    AdopterDtoProfileHasBeenAccepted.Refused,
    AdopterDtoProfileHasBeenAccepted.Blacklisted,
  ]

  const FiltersBar = () => {
    return (
      <>
        <FilterList label="Statut de l'adoptant" icon={<AssignmentTurnedIn />}>
          {profileHasBeenAcceptedCustomOrder.map((profil) => {
            const isSelected = selectedProfileHasBeenAccepted.includes(profil)

            return (
              <FilterListItem
                key={profil}
                label={`${getReadableAdopterProfileHasBeenAccepted(profil)} ${
                  profil === AdopterDtoProfileHasBeenAccepted.Waiting ? ': ' + numberOfWaitingProfiles : ''
                }`}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    // If the specie is already selected, remove it from the selectedSpecies array
                    setSelectedProfileHasBeenAccepted(selectedProfileHasBeenAccepted.filter((p) => p !== profil))
                  } else {
                    // If the specie is not selected, add it to the selectedSpecies array
                    setSelectedProfileHasBeenAccepted([...selectedProfileHasBeenAccepted, profil])
                  }
                }}
              />
            )
          })}
        </FilterList>

        <Typography variant="h6" gutterBottom sx={spacingItem}>
          Préférences des adoptants
        </Typography>

        {species && species.length > 1 && (
          <FilterList label="Espèces" icon={<Pets />}>
            {species?.map((specie) => {
              const isSelected = selectedSpecies.includes(specie)

              return (
                <FilterListItem
                  key={specie}
                  label={getReadableSpecies(specie)}
                  isSelected={isSelected}
                  toggleSelected={() => {
                    if (isSelected) {
                      // If the specie is already selected, remove it from the selectedSpecies array
                      setSelectedSpecies(selectedSpecies.filter((s) => s !== specie))
                    } else {
                      // If the specie is not selected, add it to the selectedSpecies array
                      setSelectedSpecies([...selectedSpecies, specie])
                    }
                  }}
                />
              )
            })}
          </FilterList>
        )}

        <FilterList label="Sexe" icon={<Transgender />}>
          {Object.values(AdopterDtoPreferenceSex).map((sex) => {
            const isSelected = selectedSexs.includes(sex)

            return (
              <FilterListItem
                key={sex}
                label={getReadableAdopterPreferenceSex(sex)}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    // If the sex is already selected, remove it from the selectedSexs array
                    setSelectedSexs(selectedSexs.filter((s) => s !== sex))
                  } else {
                    // If the sex is not selected, add it to the selectedSexs array
                    setSelectedSexs([...selectedSexs, sex])
                  }
                }}
              />
            )
          })}
        </FilterList>

        <FilterList label="Stérilisation" icon={<Healing />}>
          {Object.values(AdopterDtoPreferenceSterilizedOrNeutered).map((sterilisation) => {
            const isSelected = selectedSterilisations.includes(sterilisation)

            return (
              <FilterListItem
                key={sterilisation}
                label={getReadableAdopterPreferenceSterilized(sterilisation)}
                isSelected={isSelected}
                toggleSelected={() => {
                  if (isSelected) {
                    // If the sterilisation is already selected, remove it from the selectedSterilisations array
                    setSelectedSterilisations(selectedSterilisations.filter((s) => s !== sterilisation))
                  } else {
                    // If the sterilisation is not selected, add it to the selectedSexs array
                    setSelectedSterilisations([...selectedSterilisations, sterilisation])
                  }
                }}
              />
            )
          })}
        </FilterList>
        <AQuestion />
      </>
    )
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: 1,
          paddingBottom: 7.5,
          backgroundColor: 'grey.100',
          minHeight: `calc(100% - 20px)`,
          boxSizing: 'border-box',
          marginBottom: 2,
          maxHeight: `calc(100% - 20px)`,
        }}
      >
        {adoptersStore.isLoading ? (
          <CircularProgressPanel />
        ) : (
          <>
            {/* Filters for desktop version */}
            <Card
              sx={{
                ...spacingItem,
                width: 230,
                display: { xs: 'none', sm: 'block' },
                overflowY: 'auto',
              }}
            >
              <CardContent>
                <FiltersBar />
              </CardContent>
            </Card>
            {/* Filters for mobile version */}
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              sx={{
                display: { xs: 'block', sm: 'none' },
              }}
            >
              <Box sx={{ padding: 2 }}>
                <FiltersBar />
              </Box>
            </Drawer>
          </>
        )}

        <Card
          sx={{
            ...spacingItem,
            marginLeft: { xs: 0, sm: 2 },
            width: '100%',
            maxHeight: '100%',
            boxSizing: 'border-box',
          }}
        >
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              marginBottom: '1rem',
              borderBottom: '1px solid lightgray',
            }}
          >
            <Tab
              label="Tous"
              value="all"
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
            <Tab
              label="À faire"
              value="to-do"
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
            <Tab
              label="Profils à matcher"
              value="to-match"
              sx={{
                textTransform: 'none',
                borderRadius: '5px 5px 0 0',
                border: '1px solid lightgray',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
            <Tab
              label="⚙️ Paramétrer ma procédure d'adoption"
              value="settings"
              sx={{
                textTransform: 'none',
                borderBottom: 'none',
                marginRight: '5px',
                marginBottom: '-1px',
                color: 'black',
                fontWeight: 700,
                '&.Mui-selected': {
                  backgroundColor: 'white',
                  color: 'black',
                },
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
              }}
            />
          </Tabs>
          {selectedTab !== 'settings' && (
            <Box display="flex" flexDirection="row" flexWrap="nowrap">
              <TextField
                id="name-input"
                placeholder="Rechercher dans les adoptants"
                type="text"
                fullWidth
                value={searchQuery}
                onChange={onChangeSearch}
                sx={{
                  borderRadius: 0,
                  [`& fieldset`]: {
                    borderRadius: 0,
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ display: { sm: 'none' }, mx: 'auto' }}
              >
                <Tune />
              </IconButton>
            </Box>
          )}
          <Box
            sx={{
              width: '100%',
              height: '100%',
              boxSizing: 'border-box',
              overflow: 'auto',
              paddingBottom: 17, // HACK: To avoid the last row to be hidden
            }}
          >
            {selectedTab === 'settings' ? (
              <Grid container spacing={3} sx={{ paddingX: 3 }}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
                    <Typography variant="h1" sx={{ color: blue, fontSize: '1.5rem', fontWeight: 700 }}>
                      Le parcours d’adoption et carnet de santé Petso
                    </Typography>
                    <Typography sx={{ color: colors.blueSky }}>Mode d’emploi</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeaturesList title="1. Créer vos modèles">
                    <ol style={{ paddingLeft: '60px', color: colors.blueSky, fontSize: '15px' }}>
                      <li>Importer un de vos modèles PDF existants</li>
                      <li>Définissez les zones de signatures et les champs à remplir</li>
                      <li>Enregistrer votre modèle</li>
                      <li>
                        A chaque demande d’adoption Petso génère automatiquement vos documents sur la base du modèle
                        choisi
                      </li>
                    </ol>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pl: '32px' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          textAlign: 'left',
                          backgroundColor: 'rgba(247, 248, 255, 1)',
                          borderRadius: '7px',
                          paddingX: '1rem',
                          paddingY: '0.5rem',
                        }}
                      >
                        <ReportGmailerrorredOutlined sx={{ color: colors.blueSky, fontSize: '3rem' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                          <Typography
                            variant="body1"
                            sx={{
                              color: colors.cello,
                              fontSize: '11px',
                            }}
                          >
                            Si vous préferez assurer la signature des documents par vos propres moyen vous pouvez
                            séléctionner l’option “remis en main propre” lors de la création d’une procédure d’adoption.
                            <br />
                            L’étape 1 &quot;Créer vos modèles&quot; n&apos;est alors pas nécessaire.
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        sx={{ marginTop: 2, py: 1 }}
                        onClick={() => navigate('/association?tab=templates')}
                      >
                        {hasTemplate ? 'Ajouter ou modifier mes modèles' : 'Créer mon modèle de certificat et contrat'}
                      </Button>
                    </Box>
                  </FeaturesList>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'end',
                      alignItems: 'flex-end',
                      gap: 1,
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ color: 'black', textAlign: 'right', width: '100%', fontSize: '14px' }}
                    >
                      Besoin d’aide ? <span style={{ textDecoration: 'underline' }}>Consulter le tutoriel !</span>
                    </Typography>
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/1oO1jA-VLNo?si=EjP_wX0pISk9wFLq"
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeaturesList title="2. Enregistrez votre mail adressé à l’adoptant">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pl: '32px', mt: 2, maxWidth: '100%' }}>
                      <img
                        src="https://i.imgur.com/5obWTNY.png"
                        alt="Capture d'écran de l'interface email"
                        width={555}
                        height={292}
                        style={{ maxWidth: '100%' }}
                      />
                    </Box>
                  </FeaturesList>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" sx={{ color: colors.blueSky, fontSize: '15px', mt: 8 }}>
                    Pour chaque adoption, un mail est envoyé à l’adoptant lui permettant d’accéder à un espace dédié
                    pour signer les documents et si nécessaire régler les frais d’adoption.
                    <br /> <br />
                    Pour des raisons techniques, ce mail est envoyé par l’adresse contact@petso.fr
                    <br /> <br />
                    <b>Vous pouvez pour autant le signer et le personnaliser selon vos souhaits.</b>
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: hasEmailTemplate ? theme.palette.success.main : blue,
                      fontSize: '15px',
                      fontWeight: 700,
                      mt: 4,
                    }}
                  >
                    {hasEmailTemplate
                      ? 'Votre mail est bien enregistré !'
                      : 'Une fois finalisé pensez à bien enregistrer !'}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    sx={{ marginTop: 1, py: 1 }}
                    onClick={() => navigate('./email-template')}
                  >
                    {hasEmailTemplate ? 'Modifier' : 'Configurer'} mon mail
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeaturesList title="3. Signature et validation des documents">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pl: '32px' }}>
                      <Typography variant="body1" sx={{ color: colors.blueSky, fontSize: '15px', mt: 2 }}>
                        Une fois la procédure d’adoption crée, l’adoptant accède à son espace pour signer les documents
                        et vous les renvoyer.
                        <br /> <br />
                        Vous êtes notifiés de la réception et en mesure de les valider/refuser-redemander la signature.
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          textAlign: 'left',
                          backgroundColor: 'rgba(247, 248, 255, 1)',
                          borderRadius: '7px',
                          paddingX: '1rem',
                          paddingY: '0.5rem',
                        }}
                      >
                        <ReportGmailerrorredOutlined sx={{ color: colors.blueSky, fontSize: '3rem' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                          <Typography
                            variant="body1"
                            sx={{
                              color: colors.cello,
                              fontSize: '11px',
                            }}
                          >
                            Vous devez débloquer le contrat pour qu’il soit accéssible par l’adoptant pour signature,
                            conformement au 8 jours de délai légal
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </FeaturesList>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FeaturesList title="4. Réglement des frais d’adoption en ligne">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pl: '32px' }}>
                      <Typography variant="body1" sx={{ color: colors.blueSky, fontSize: '15px', mt: 2 }}>
                        Nous proposons une fonctionnalité de réglement de frais d’adoption en ligne sans frais.
                        <br /> <br />
                        Une fois un réglement effectué, le statut de paiement est automatiquement validée dans votre
                        procédure.
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          textAlign: 'left',
                          backgroundColor: 'rgba(247, 248, 255, 1)',
                          borderRadius: '7px',
                          paddingX: '1rem',
                          paddingY: '0.5rem',
                        }}
                      >
                        <ReportGmailerrorredOutlined sx={{ color: colors.blueSky, fontSize: '3rem' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                          <Typography
                            variant="body1"
                            sx={{
                              color: colors.cello,
                              fontSize: '11px',
                            }}
                          >
                            Le réglement en ligne représente un coût. Nous sollicitons les adoptants via un système de
                            pourboire afin de les compenser, rien de plus !
                          </Typography>
                        </Box>
                      </Box>
                      <Typography
                        variant="body1"
                        component="a"
                        href="https://51lloy792xs.typeform.com/to/qrrDASTi"
                        target="_blank"
                        sx={{ color: 'black', width: '100%', fontSize: '13px' }}
                      >
                        &gt;&gt; Si cette fonctionnalité vous intéresse, complétez ce formulaire pour configurer cette
                        fonctionnalité et faciliter la transmission des versements
                      </Typography>
                    </Box>
                  </FeaturesList>
                </Grid>
                <Grid item xs={12} md={12}>
                  <FeaturesList title="5. Adoption terminée ! Le carnet de santé digitale pour chaque adoptant">
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pl: '32px' }}>
                      <Typography variant="body1" sx={{ color: colors.blueSky, fontSize: '15px', mt: 2 }}>
                        Une fois adopté, l’adoptant conserve gratuitement l’espace de son animal qu’il pourra compléter
                        dans le temps
                        <br />
                        Transmettez et échangez depuis Petso avec chaque adoptants :
                      </Typography>
                      <ul style={{ color: colors.blueSky, fontSize: '15px', marginTop: 0 }}>
                        <li>Antécédents de santé & rappels des prochains rendez-vous (vermifuges, vaccins...)</li>
                        <li>
                          Date de stérilisation prévue (si demandée). L’adoptant pourra vous envoyer le justificatif
                        </li>
                        <li>Transmission des documents (certificat vétérinaire...)</li>
                      </ul>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'start',
                          alignItems: 'center',
                          textAlign: 'left',
                          backgroundColor: 'rgba(105, 172, 115, 0.4)',
                          borderRadius: '7px',
                          paddingX: '1rem',
                          paddingY: '0.5rem',
                        }}
                      >
                        <ReportGmailerrorredOutlined sx={{ color: 'rgba(107, 107, 107, 1)', fontSize: '3rem' }} />
                        <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
                          <Typography
                            variant="body1"
                            sx={{
                              color: 'rgba(107, 107, 107, 1)',
                              fontSize: '11px',
                            }}
                          >
                            Dans le cadre de notre collaboration, nous offrons à chaque adoptant une semaine gratuite de
                            nos services et recommandations auprès de notre séléction partenaire pour favoriser
                            l’arrivée et le bien-être de l’animal !
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </FeaturesList>
                </Grid>
              </Grid>
            ) : adoptersStore.isLoading ? (
              <CircularProgressPanel />
            ) : (
              <SearchResults
                members={adopters}
                searchQuery={searchQuery}
                filters={{ selectedSpecies, selectedSexs, selectedSterilisations, selectedProfileHasBeenAccepted }}
              />
            )}
          </Box>
        </Card>
      </Box>

      {account && accountCanEdit(account) && (
        <Box sx={{ position: 'absolute', right: 2, bottom: 0, margin: 2 }}>
          <Fab color="primary" variant="extended" onClick={() => navigate(PATHS.ajouterAdoptant.absolute)}>
            <Add sx={{ mr: 1 }} />
            Ajouter un Adoptant
          </Fab>
        </Box>
      )}
    </>
  )
}

type SortDirection = 'asc' | 'desc'
type SortColumn = 'firstName' | 'lastName' | 'email' | 'profileHasBeenAccepted' | 'phoneNumber'

type SearchResultsProps = {
  members: Array<AdopterDto>
  searchQuery: string
  filters?: {
    selectedSpecies: AnimalDtoSpecies[]
    selectedSexs: AdopterDtoPreferenceSex[]
    selectedSterilisations: AdopterDtoPreferenceSterilizedOrNeutered[]
    selectedProfileHasBeenAccepted: AdopterDtoProfileHasBeenAccepted[]
  }
}

const SearchResults: React.FC<SearchResultsProps> = ({ members, searchQuery, filters }) => {
  const [sortColumn, setSortColumn] = React.useState<SortColumn>('profileHasBeenAccepted')
  const [sortDirection, setSortDirection] = React.useState<SortDirection>('asc')
  const [expandCardIndex, setExpandCardIndex] = React.useState<number | null>(null)
  const isMobile = useMediaQuery('(max-width:600px)')
  const handleSort = (column: SortColumn, columnDefaultDirection: SortDirection) => {
    if (sortColumn === column) {
      setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
    } else {
      setSortDirection(columnDefaultDirection)
    }

    setSortColumn(column)
  }
  const searchResult = searchAdopters(members, searchQuery)
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedSpecies.length) {
        return true
      }
      return member.preferences.species
        ? member.preferences.species.some((specie) => filters.selectedSpecies.includes(specie))
        : false
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedSexs.length) {
        return true
      }
      return member.preferences.sex ? filters.selectedSexs.includes(member.preferences.sex) : false
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedSterilisations.length) {
        return true
      }
      return member.preferences.sterilizedOrNeutered
        ? filters.selectedSterilisations.includes(member.preferences.sterilizedOrNeutered)
        : false
    })
    .filter((member) => {
      if (!filters) return true
      if (!filters.selectedProfileHasBeenAccepted.length) {
        return true
      }
      return member.profileHasBeenAccepted
        ? filters.selectedProfileHasBeenAccepted.includes(member.profileHasBeenAccepted)
        : false
    })
    .sort((a, b) => {
      switch (sortColumn) {
        case 'firstName':
          return sortAlphabetically(a, b, sortDirection, 'firstName')
        case 'lastName':
          return sortAlphabetically(a, b, sortDirection, 'lastName')
        case 'email':
          return sortAlphabetically(a, b, sortDirection, 'email')
        case 'profileHasBeenAccepted':
          return sortAdopters(a, b)
        case 'phoneNumber':
          return sortAlphabetically(a, b, sortDirection, 'phoneNumber', 'firstName')
        default:
          throw new Error(`sortColumn ${sortColumn} not supported`)
      }
    })

  return !isMobile ? (
    // Desktop version
    <Table stickyHeader aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'firstName'}
              direction={sortDirection}
              onClick={() => {
                handleSort('firstName', 'asc')
              }}
            >
              {getReadableAdopterFieldLabel('firstName')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'lastName'}
              direction={sortDirection}
              onClick={() => {
                handleSort('lastName', 'asc')
              }}
            >
              {getReadableAdopterFieldLabel('lastName')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'profileHasBeenAccepted'}
              direction={sortDirection}
              onClick={() => {
                handleSort('profileHasBeenAccepted', 'asc')
              }}
            >
              Statut de l&#39;adoptant
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'email'}
              direction={sortDirection}
              onClick={() => {
                handleSort('email', 'asc')
              }}
            >
              {getReadableAdopterFieldLabel('email')}
            </TableSortLabel>
          </TableCell>
          <TableCell>
            <TableSortLabel
              active={sortColumn === 'phoneNumber'}
              direction={sortDirection}
              onClick={() => {
                handleSort('phoneNumber', 'asc')
              }}
            >
              Adoption(s) en cours
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody sx={{ height: '100%' }}>
        {searchResult.map((member) => (
          <AdopterListItem key={member.id} member={member} />
        ))}
      </TableBody>
    </Table>
  ) : (
    // Mobile version
    <Stack spacing={1.5} marginTop="10px" marginBottom="80px" marginX="15px">
      {searchResult.map((member, index) => (
        <AdopterListCard
          key={member.id}
          member={member}
          index={index}
          expandCardIndex={expandCardIndex}
          setExpandCardIndex={setExpandCardIndex}
        />
      ))}
    </Stack>
  )
}

type ItemProps = {
  member: AdopterDto
}

const AdopterListItem: React.FC<ItemProps> = ({ member }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${member.id}`)
  }

  return (
    <TableRow onClick={handleClick} sx={{ cursor: 'pointer', height: '100%' }}>
      <TableCell component="th" scope="row" sx={{ fontWeight: 700 }}>
        {member.firstName}
      </TableCell>
      <TableCell component="th" scope="row">
        {member.lastName}
      </TableCell>
      <TableCell component="th" scope="row">
        {member.profileHasBeenAccepted && (
          <Chip
            label={getReadableAdopterProfileHasBeenAccepted(member.profileHasBeenAccepted)}
            sx={{
              backgroundColor: setOpacity(getColorForAdopterProfileHasBeenAccepted(member.profileHasBeenAccepted), 0.8),
              color: isColorDark(getColorForAdopterProfileHasBeenAccepted(member.profileHasBeenAccepted))
                ? '#fff'
                : '#000',
            }}
          />
        )}
      </TableCell>
      <TableCell component="th" scope="row">
        {member.email}
      </TableCell>

      <TableCell component="th" scope="row">
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', maxWidth: '60%' }}>
          {member.adoptionAttempts?.map((attempt) => {
            return (
              <AdoptionAttemptChip
                key={attempt.id}
                member={member}
                attempt={attempt}
                avatarSx={animalAvatarSx}
                textSx={adoptionChipTextSx}
              />
            )
          })}
        </Box>
      </TableCell>
    </TableRow>
  )
}

type ChipProps = {
  member: AdopterDto
  attempt: AdoptionAttemptDto
  avatarSx: SxProps
  textSx: SxProps
}

// AdoptionAttemptsChip props

const animalAvatarSx = {
  height: '35px !important',
  width: '35px !important',
  ml: '-1px !important',
  mt: '-1px !important',
}
const adoptionChipTextSx = {
  fontSize: 12,
  height: 35,
  pl: 0,
  gap: 0,
}

export const AdoptionAttemptChip: React.FC<ChipProps> = ({ member, attempt, avatarSx, textSx }) => {
  const navigate = useNavigate()

  const order: Array<AnimalInsightsDtoType> = ['info', 'error', 'warning']
  const insights = attempt.insights || []
  const insightType = attempt.insights && attempt.insights.length > 0 ? attempt.insights[0].type : undefined
  const insightsCount = attempt.insights ? attempt.insights.length : 0 // Calculate the number of insights

  return (
    <HtmlTooltip
      onClick={(e) => e.stopPropagation()}
      title={
        insightType ? (
          <React.Fragment>
            {insights
              .sort((a, b) => {
                const typeA = a.type
                const typeB = b.type

                // Get the index of each type in the order array
                const indexA = order.indexOf(typeA)
                const indexB = order.indexOf(typeB)

                // Compare the indexes to determine the sorting order
                return indexA - indexB
              })
              .map((insight, index) => {
                // eslint-disable-next-line react/jsx-key
                return (
                  <InfoBox key={index} messageType={insight.type} content={insight.message} sx={{ marginBottom: 1 }} />
                )
              })}
          </React.Fragment>
        ) : null
      }
    >
      <Chip
        clickable
        onClick={(e) => {
          e.stopPropagation()
          navigate(
            `${PATHS.suiviAdoptant.absolute}/${member.id}?tab=${getTabForAdoptionAttemptStatus(
              attempt
            )}-adoption&attemptId=${attempt.id}`
          )
        }}
        avatar={
          <Avatar alt={`Photo de l'animal`} src={getAnimalProfilePhotoSrc(`${attempt.animalId}.jpg`)} sx={avatarSx} />
        }
        label={getAdoptionAttemptStatus(attempt)}
        variant="outlined"
        sx={{
          ...textSx,
          background: (theme) => (insightType ? setOpacity(theme.palette[insightType].main, 0.2) : 'white'),
          '&:hover': {
            backgroundColor: (theme) =>
              insightType ? `${setOpacity(theme.palette[insightType].main, 0.4)} !important` : 'white',
          },
          position: 'relative', // Ensure the Chip is relative for absolute positioning
          '&::before': {
            content: `"${insightsCount}"`, // Display the number of insights
            position: 'absolute',
            display: insightsCount > 0 ? 'flex' : 'none', // Only display if there are insights
            justifyContent: 'center',
            alignItems: 'center',
            top: '0px',
            right: '0px',
            backgroundColor: (theme) => (insightType ? theme.palette[insightType].main : 'white'), // Background color for contrast
            color: 'white', // Text color for contrast
            fontWeight: 700, // Bold font for better visibility
            borderRadius: '50%', // Make it circular
            width: '24px', // Adjust width for better size
            height: '24px', // Adjust height for better size
            padding: '2px 5px', // Adjust padding for better size
            fontSize: '15px', // Adjust font size as needed
            transform: 'translate(50%, -50%)', // Adjust positioning slightly outside the top-right
          },
        }}
      />
    </HtmlTooltip>
  )
}

export function getTabForAdoptionAttemptStatus(attempt: AdoptionAttemptDto): 'ongoing' | 'past' | 'suspended' {
  if (attempt.status === AdoptionAttemptDtoStatus.Done && attempt.step > 5) {
    return 'past'
  }
  if (
    attempt.status === AdoptionAttemptDtoStatus.SuspendedByCustomer ||
    attempt.status === AdoptionAttemptDtoStatus.SuspendedByUser
  ) {
    return 'suspended'
  }
  return 'ongoing'
}
