import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import {
  AdopterDto,
  AdopterDtoProfileHasBeenAccepted,
  AdoptionAttemptDto,
  AdoptionAttemptEventDtoType,
  AnimalAdoptionDtoStatus,
  AnimalAdoptionDtoStep,
  CreateAdopterBodyDto,
} from '../../interactors/gen/backendClient'
import { useFormExtended } from '../../utils/hooks/useFormExtended'
import { StepOne } from './AddAdopterSteps/StepOne'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { StepTwo } from './AddAdopterSteps/StepTwo'
import { StepThree } from './AddAdopterSteps/StepThree'
import { LoadingButton } from '@mui/lab'
import { useNavigate } from 'react-router-dom'
import { PATHS } from '../PATHS'
import { StepFive } from './AddAdopterSteps/StepFive'
import { StepFour } from './AddAdopterSteps/StepFour'
import { StepSix } from './AddAdopterSteps/StepSix'
import dayjs from 'dayjs'
import { useAccountsStore } from '../../store/AccountsStore'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import { useAdoptersStore } from '../../store/AdoptersStore'
import { useFetchOrRefreshAdoptersOnMount } from '../../store/useFetchOrRefreshAdoptersOnMount'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { StepSeven } from './AddAdopterSteps/StepSeven'
import { StepEight } from './AddAdopterSteps/StepEight'
import { nanoid } from 'nanoid'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  adopterData: CreateAdopterBodyDto | null
  secondaryPage?: boolean
}

export interface StepsFormParams {
  formSent: 'true' | 'false' | 'not-necessary'
  validateProfile: 'true' | 'false' | 'not-necessary'
  hasAnimal: boolean
  doneAnimalMeeting: 'true' | 'false' | 'not-necessary'
  gavedCertificate: boolean
  gotSignedCertificate: boolean
  gavedContract: boolean
  gotSignedContract: boolean
  oldAdopter: boolean
  animalId: string
  customerComment: string
  date: string
  memberInCharge: string
  details: string
}

type neededSteps = {
  2: boolean
  3: boolean
  4: boolean
  5: boolean
  6: boolean
  7: boolean
  8: boolean
}

export const AddAdopterDialog: React.FC<Props> = ({ open, setOpen, adopterData, secondaryPage = false }) => {
  const navigate = useNavigate()
  const accountsStore = useAccountsStore()
  const adoptersStore = useAdoptersStore()
  useFetchOrRefreshAdoptersOnMount()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [adopter, setAdopter] = useState<AdopterDto | null>(null)
  const [adoptionAttempt, setAdoptionAttempt] = useState<AdoptionAttemptDto | undefined>(undefined)
  const [file, setFile] = useState<File | null>(null)

  const handleClose = () => {
    setOpen(false)
  }

  const [neededSteps, setNeededSteps] = useState<neededSteps | null>(null)
  const setNeededStepsAsync = async (steps: neededSteps | null) => {
    return new Promise((resolve) => {
      setNeededSteps(steps)
      resolve(true)
    })
  }
  const [neededAnimalMeeting, setNeededAnimalMeeting] = useState<boolean>(true)

  // We need to find the next needed step and set it as CurrentStep
  const goNextStep = (
    updatedNeededSteps: neededSteps | null = null,
    adopterId?: string,
    oldAdopter?: boolean,
    attemptId?: string
  ) => {
    const stepsToUse = updatedNeededSteps || neededSteps
    console.log('neededSteps', stepsToUse)
    if (oldAdopter) {
      return navigate(`${PATHS.assignerAnimal.absolute}?adopterId=${adopterId}&type=pastAdoption`)
    }
    if (stepsToUse === null) {
      return setCurrentStep(currentStep + 1)
    }
    const nextStep = Object.entries(stepsToUse)
      .sort(([a], [b]) => Number(a) - Number(b)) // Ensure steps are sorted in ascending order
      .find(([step, needed]) => needed === true && Number(step) > currentStep) // Find the first needed step that comes after the current step
    if (nextStep) {
      setCurrentStep(Number(nextStep[0]))
    } else {
      handleClose()
      if (adoptionAttempt || attemptId) {
        return navigate(
          `${PATHS.suiviAdoptant.absolute}/${adopter?.id}/?tab=ongoing-adoption&attemptId=${
            adoptionAttempt?.id || attemptId
          }`
        )
      }
      return navigate(`${PATHS.suiviAdoptant.absolute}/${adopter?.id || adopterId}`)
    }
  }

  const hasNextStep = () => {
    if (neededSteps === null) {
      return true
    }
    const nextStep = Object.entries(neededSteps)
      .sort(([a], [b]) => Number(a) - Number(b)) // Ensure steps are sorted in ascending order
      .find(([step, needed]) => needed === true && Number(step) > currentStep)
    return nextStep !== undefined
  }

  const getDefaultValues = (_: AdopterDto | null): StepsFormParams => {
    return {
      formSent: 'false',
      validateProfile: 'false',
      hasAnimal: false,
      doneAnimalMeeting: 'false',
      gavedCertificate: false,
      gotSignedCertificate: false,
      gavedContract: false,
      gotSignedContract: false,
      oldAdopter: secondaryPage,
      animalId: '',
      customerComment: '',
      date: '',
      memberInCharge: '',
      details: '',
    }
  }

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
  } = useFormExtended(adopter, getDefaultValues)

  const allAdoptersEmail = adoptersStore.adopters
    .filter((adopter) => adopter.isDeleted !== true)
    .map((adopter) => adopter.email)

  const onSubmit = async (data: StepsFormParams) => {
    setIsLoading(true)

    let adopterId = ''
    let attemptId = ''
    if (currentStep === 1) {
      if (adopterData?.email === '' || adopterData?.email == null || allAdoptersEmail.includes(adopterData?.email)) {
        globalSnackBarStore.triggerErrorMessage('Cette adresse email est déjà utilisée')
        setIsLoading(false)
        return setOpen(false)
      }
      const adopterFirstStep = await AdoptersClient.createAdopter(adopterData as CreateAdopterBodyDto)
      adopterId = adopterFirstStep.id
      setAdopter(adopterFirstStep)
      if (data.oldAdopter) {
        goNextStep(null, adopterId, true)
        return setIsLoading(false)
      }
      if (!adopterFirstStep) {
        return console.log('Erreur')
      }
      await setNeededStepsAsync({
        2: data.formSent === 'true' || data.formSent === 'not-necessary',
        3: data.hasAnimal === true,
        4: data.doneAnimalMeeting === 'true',
        5: data.gavedCertificate === true,
        6: data.gotSignedCertificate === true,
        7: data.gavedContract === true,
        8: data.gotSignedContract === true,
      })
      if (data.formSent === 'false') {
        await AdoptersClient.sendForm(adopterFirstStep.id)
      }
      if (data.validateProfile === 'true' || data.validateProfile === 'not-necessary') {
        adopterFirstStep.profileHasBeenAccepted = AdopterDtoProfileHasBeenAccepted.Accepted
        await AdoptersClient.editAccount(adopterFirstStep)
      }
      if (data.doneAnimalMeeting === 'not-necessary') {
        setNeededAnimalMeeting(false)
      }
    }
    if (currentStep === 2) {
      if (!adopter) {
        return
      }
      let modifiedAdopter = false
      if (isDirty) {
        adopter.adoptionForm = { ...adopter.adoptionForm, customerComments: data.customerComment }
        modifiedAdopter = true
      }
      if (adopter.profileHasBeenAccepted !== AdopterDtoProfileHasBeenAccepted.Accepted) {
        adopter.profileHasBeenAccepted = AdopterDtoProfileHasBeenAccepted.FormAccepted
        modifiedAdopter = true
      }
      if (modifiedAdopter) {
        await AdoptersClient.editAccount(adopter)
      }
    }
    if (currentStep === 3) {
      if (!adopter) {
        return
      }
      if (adopter.profileHasBeenAccepted === AdopterDtoProfileHasBeenAccepted.Accepted) {
        const animal = await AnimalsClient.getAnimal(data.animalId)
        if (animal.adoption.adopterId && animal.adoption.adopterId !== adopter.id) {
          const adopter = adoptersStore.adopters.find((adopter) => adopter.id === animal.adoption.adopterId)
          if (adopter) {
            adopter.adoptionAttempts?.forEach((attempt) => {
              if (attempt.animalId === animal.id) {
                attempt.status = 'suspended-by-customer'
                AdoptersClient.updateAdoptionAttempt(adopter.id, attempt)
              }
            })
          }
        }
        animal.adoption.adopterId = adopter.id
        animal.adoption.status = AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable
        animal.adoption.step = AnimalAdoptionDtoStep.AdoptionToBeValidated
        animal.adoption.adoptionDate = undefined
        animal.adoption.exitDate = undefined
        animal.adoption.vpaDate = undefined
        await AnimalsClient.editAnimal(animal)
        await AdoptersClient.newAdoptionAttempt(adopter.id, data.animalId, false).then(async (attempt) => {
          setAdoptionAttempt(attempt)
          attemptId = attempt.id
          if (!neededAnimalMeeting) {
            const newAttempt: AdoptionAttemptDto = {
              ...attempt,
              events: [
                ...attempt.events,
                {
                  id: nanoid(),
                  type: AdoptionAttemptEventDtoType.MeetingWithAdopter,
                  date: dayjs().toISOString(),
                  memberInChargeId: accountsStore.connectedAccount?.id || '',
                  comment: "Vous avez indiqué que la rencontre avec l'animal n'était pas nécéssaire",
                },
              ],
            }
            const nextAttempt = await AdoptersClient.updateAdoptionAttempt(adopter.id, newAttempt)
            setAdoptionAttempt(nextAttempt)
          }
        })
      } else {
        adopter.desiredAnimal = { hasDesiredAnimal: true, desiredAnimalId: data.animalId }
        await AdoptersClient.editAccount(adopter)
      }
    }
    if (currentStep === 4) {
      if (!adopter) {
        return
      }
      if (!adoptionAttempt) {
        return console.log('No adoption attempt')
      }
      const newAttempt: AdoptionAttemptDto = {
        ...adoptionAttempt,
        events: [
          ...adoptionAttempt.events,
          {
            id: nanoid(),
            type: AdoptionAttemptEventDtoType.MeetingWithAdopter,
            date: dayjs(data.date).toISOString(),
            memberInChargeId: data.memberInCharge,
            comment: data.details,
          },
        ],
      }
      await AdoptersClient.updateAdoptionAttempt(adopter.id, newAttempt)
    }
    if (currentStep === 5) {
      if (!adopter) {
        return
      }
      if (!adoptionAttempt) {
        return console.log('No adoption attempt')
      }

      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }

      await AdoptersClient.addOriginalDocumentToAdoptionAttempt(
        adopter.id,
        adoptionAttempt.id,
        formData,
        'certificate'
      ).then((r) => {
        setAdoptionAttempt(r)
        setFile(null)
      })
    }
    if (currentStep === 6) {
      if (!adopter) {
        return
      }
      if (!adoptionAttempt) {
        return console.log('No adoption attempt')
      }
      const newAttempt: AdoptionAttemptDto = {
        ...adoptionAttempt,
        events: [
          ...adoptionAttempt.events,
          {
            id: nanoid(),
            type: AdoptionAttemptEventDtoType.HomeCheck,
            date: dayjs().toISOString(),
            memberInChargeId: accountsStore.connectedAccount?.id || '',
            comment: "Vous avez indiqué que la visite du domicile n'était pas nécéssaire",
          },
        ],
      }
      await AdoptersClient.updateAdoptionAttempt(adopter.id, newAttempt)
      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }

      await AdoptersClient.addSignedCertificateOrContractToAdoptionAttempt(
        adopter.id,
        adoptionAttempt.id,
        'certificate',
        formData
      ).then((r) => {
        setAdoptionAttempt(r)
        setFile(null)
      })
    }
    if (currentStep === 7) {
      if (!adopter) {
        return
      }
      if (!adoptionAttempt) {
        return console.log('No adoption attempt')
      }

      const newAttempt: AdoptionAttemptDto = {
        ...adoptionAttempt,
        verifiedPaymentInfo: true,
        verifiedPriceInfo: true,
      }
      await AdoptersClient.updateAdoptionAttempt(adopter.id, newAttempt)

      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }

      await AdoptersClient.addOriginalDocumentToAdoptionAttempt(
        adopter.id,
        adoptionAttempt.id,
        formData,
        'contract'
      ).then((r) => {
        setAdoptionAttempt(r)
        setFile(null)
      })
    }
    if (currentStep === 8) {
      if (!adopter) {
        return
      }
      if (!adoptionAttempt) {
        return console.log('No adoption attempt')
      }

      const formData = new FormData()
      if (file) {
        formData.append('file', file)
      }

      await AdoptersClient.addSignedCertificateOrContractToAdoptionAttempt(
        adopter.id,
        adoptionAttempt.id,
        'contract',
        formData
      ).then((r) => {
        setAdoptionAttempt(r)
        setFile(null)
      })
    }
    if (currentStep === 1) {
      const neededSteps: neededSteps = {
        2: data.formSent === 'true' || data.formSent === 'not-necessary',
        3: data.hasAnimal === true,
        4: data.doneAnimalMeeting === 'true',
        5: data.gavedCertificate === true,
        6: data.gotSignedCertificate === true,
        7: data.gavedContract === true,
        8: data.gotSignedContract === true,
      }
      goNextStep(neededSteps, adopterId)
      return setIsLoading(false)
    }
    if (currentStep === 3) {
      goNextStep(null, adopterId, undefined, attemptId)
      return setIsLoading(false)
    }
    goNextStep()
    return setIsLoading(false)
  }

  const renderStep = () => {
    const stepProps = { control: control, register: register, errors: errors, setValue, watch }

    switch (currentStep) {
      case 1:
        return <StepOne {...stepProps} secondaryPage={secondaryPage} />
      case 2:
        return <StepTwo {...stepProps} />
      case 3:
        return <StepThree {...stepProps} />
      case 4:
        return <StepFour {...stepProps} />
      case 5:
        return <StepFive {...stepProps} setFile={setFile} />
      case 6:
        return <StepSix {...stepProps} setFile={setFile} />
      case 7:
        return <StepSeven {...stepProps} setFile={setFile} />
      case 8:
        return <StepEight {...stepProps} setFile={setFile} />
      default:
        return null
    }
  }
  return (
    <Dialog onClose={() => console.log('trying to close')} open={open}>
      <DialogTitle>Où en êtes-vous avec cet adoptant ?</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>{renderStep()}</DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <LoadingButton variant="outlined" onClick={handleClose}>
            Annuler
          </LoadingButton>
          <LoadingButton variant="contained" type="submit" loading={isLoading}>
            {hasNextStep() ? (currentStep === 5 && !file ? 'Continuer sans upload' : 'Suivant') : 'Terminer'}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
