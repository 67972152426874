import { Editor } from '@tinymce/tinymce-react'
import { Editor as TinyMCEEditor } from 'tinymce'
import React from 'react'
import { templateVariablesList } from '../../domain/Customer/TemplateVariables'

interface Props {
  editorContent: string
  setEditorContent: React.Dispatch<React.SetStateAction<string>>
  logoUrl: string
  signatureUrl: string
  preventAnchorDeletion?: boolean // Optional boolean prop
}

export const CustomEditor: React.FC<Props> = ({
  editorContent,
  setEditorContent,
  logoUrl,
  signatureUrl,
  preventAnchorDeletion = false, // Default value set to false
}) => {
  const templateVariables = [
    ...templateVariablesList,
    { label: "Logo de l'asso", value: `<img src="${logoUrl}" alt="Logo" style="width: 150px; height: auto;" />` },
    {
      label: "Signature de l'asso",
      value: `<img src="${signatureUrl}" alt="Signature" style="width: 300px; height: auto;" />`,
    },
  ]

  const protectedHrefBase = 'https://adoptant.petso.fr/create-password?token='

  const handleEditorChange = (content: string) => {
    setEditorContent(content)
  }

  return (
    <Editor
      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
      value={editorContent}
      onEditorChange={handleEditorChange}
      init={{
        height: 500,
        menubar: false,
        plugins: [
          'advlist',
          'autolink',
          'lists',
          'link',
          'image',
          'charmap',
          'preview',
          'anchor',
          'searchreplace',
          'visualblocks',
          'code',
          'fullscreen',
          'insertdatetime',
          'image',
          'table',
          'help',
          'wordcount',
        ],
        toolbar:
          'undo redo | fontfamily fontsize lineheight | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | customInsertButton | bullist numlist outdent indent | removeformat | help image',
        setup: (editor: TinyMCEEditor) => {
          // Add a button for each variable
          templateVariables.forEach((variable) => {
            editor.ui.registry.addButton(variable.label, {
              text: variable.label,
              onAction: () => {
                editor.insertContent(variable.value)
              },
            })
          })

          // Create a dropdown menu for variables
          editor.ui.registry.addMenuButton('customInsertButton', {
            text: 'Insérer une variable',
            fetch: (callback) => {
              const items = templateVariables.map((variable) => ({
                type: 'menuitem',
                text: variable.label,
                onAction: () => editor.insertContent(variable.value),
              }))
              callback(items as ToggleMenuItemSpec[])
            },
          })

          // Prevent deletion of specific anchor elements
          if (preventAnchorDeletion) {
            editor.on('BeforeExecCommand', (e) => {
              if (e.command === 'Delete') {
                const selectedNode = editor.selection.getNode()
                const anchorElement = selectedNode.closest('a')
                if (
                  anchorElement &&
                  anchorElement.tagName === 'A' &&
                  anchorElement.getAttribute('href')?.startsWith(protectedHrefBase)
                ) {
                  e.preventDefault()
                  editor.notificationManager.open({
                    type: 'warning',
                    text: 'Vous ne pouvez pas supprimer ce lien protégé.',
                    timeout: 3000,
                  })
                }
              }
            })

            editor.on('BeforeSetContent', (e) => {
              const parser = new DOMParser()
              const doc = parser.parseFromString(e.content, 'text/html')
              const anchors = doc.querySelectorAll('a')
              const containsProtectedLink = Array.from(anchors).some((anchor) =>
                anchor.getAttribute('href')?.startsWith(protectedHrefBase)
              )
              if (!containsProtectedLink) {
                e.preventDefault()
                editor.notificationManager.open({
                  type: 'error',
                  text: 'Le contenu ne peut pas supprimer le lien protégé.',
                  timeout: 3000,
                })
              }
            })
          }
        },
      }}
    />
  )
}

interface CommonMenuItemSpec {
  enabled?: boolean
  text?: string
  value?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: Record<string, any>
  shortcut?: string
}

interface ToggleMenuItemSpec extends CommonMenuItemSpec {
  type?: 'togglemenuitem'
  icon?: string
  active?: boolean
  onSetup?: (api: ToggleMenuItemInstanceApi) => void
  onAction: (api: ToggleMenuItemInstanceApi) => void
}

interface CommonMenuItemInstanceApi {
  isEnabled: () => boolean
  setEnabled: (state: boolean) => void
}

interface ToggleMenuItemInstanceApi extends CommonMenuItemInstanceApi {
  isActive: () => boolean
  setActive: (state: boolean) => void
}
