import React, { useCallback, useEffect, useState } from 'react'
import { colors } from '../../theme'
import { Close, ReportGmailerrorredOutlined } from '@mui/icons-material'
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  Typography,
  Grid,
  InputAdornment,
  TextField,
  DialogActions,
  Tooltip,
  Button,
} from '@mui/material'
import {
  AdoptionAttemptDto,
  AdoptionAttemptDtoSterilizationDepositStatus,
  AnimalAdoptionDtoOfferType,
  AnimalAdoptionDtoStatus,
  AnimalAdoptionDtoStep,
  AnimalDto,
  AnimalHealthOperationDto,
  CreateAnimalBodyDtoVaccinationStatus,
} from '../../../interactors/gen/backendClient'
import { AxiosError } from 'axios'
import { UseMutationResult } from 'react-query'
import { useFormExtended } from '../../../utils/hooks/useFormExtended'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import { nanoid } from 'nanoid'
import {
  getColorForAnimalVaccineStatus,
  getReadableAnimalAdoptionOfferType,
  getReadableAnimalVaccineStatus,
} from '../../../domain/Animal/AnimalDisplay'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { ColoredChipSelectField } from '../../common/ColoredChipSelectField'
import { LoadingButton } from '@mui/lab'
import { backendClient } from '../../../interactors/clients/client'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { ControlledDateField } from '../../common/ControlledDateField'
import { getReadableDepositStatus, getColorForDepositStatus } from '../../../domain/Adopters/AccountDisplay'
import dayjs from 'dayjs'
import { useDropzone } from 'react-dropzone'
import { AdoptersClient } from '../../../interactors/clients/AdoptersClient'
import { useNavigate } from 'react-router-dom'

interface Props {
  open: boolean
  setOpen: (open: boolean) => void
  adopterId: string
  adopterEmail: string
  animal: AnimalDto
  attempt: AdoptionAttemptDto
  updateAttemptMutation: UseMutationResult<
    AdoptionAttemptDto,
    Error | AxiosError<unknown, unknown>,
    AdoptionAttemptDto,
    unknown
  >
  setAdoptionAttempt: (attempt: AdoptionAttemptDto) => void
  setAnimal: (animal: AnimalDto) => void
}

interface FormParams {
  offerType?: AnimalAdoptionDtoOfferType
  priceInEuros?: number
  paymentDone: string
  needed: string
  depositAmount: number
  depositStatus?: string
  date: string
  exitDate: string | ''
  adoptionDate: string | ''
  vpaDate: string | ''
  newName: string | ''
  vaccinationStatus?: CreateAnimalBodyDtoVaccinationStatus
  nextVaccinationDate?: string
  nextDewormingDate?: string
  nextFleaControlDate?: string
}

export const EndAdoptionPopUp: React.FC<Props> = ({
  open,
  setOpen,
  animal,
  attempt,
  updateAttemptMutation,
  adopterId,
  adopterEmail,
  setAdoptionAttempt,
  setAnimal,
}) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [files, setFiles] = useState<(File | null)[]>([null, null, null]) // Slot 0, Slot 1 and Slot 2

  const navigate = useNavigate()

  // Custom function to handle file placement in a specific slot
  const handleDrop = useCallback(
    (acceptedFiles: File[], slot: number) => {
      const updatedFiles = [...files]
      // Place the file into the specified slot
      if (acceptedFiles.length > 0) {
        updatedFiles[slot] = acceptedFiles[0] // Replace file in the specific slot
      }

      setFiles(updatedFiles) // Update state
    },
    [files]
  )

  // Dropzone for slot 0
  const dropzoneForInfoDoc = useDropzone({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 0), // Handle drop for slot 0
    accept: {
      'application/pdf': ['.pdf'],
    },
  })

  // Dropzone for slot 1
  const dropzoneForAttestation = useDropzone({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 1), // Handle drop for slot 1
    accept: {
      'application/pdf': ['.pdf'],
    },
  })

  // Dropzone for slot 2
  const dropzoneForVeterinaryCert = useDropzone({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 2), // Handle drop for slot 1
    accept: {
      'application/pdf': ['.pdf'],
    },
  })

  const nextVaccinationOperation = () => {
    const operation = animal.health.operations?.find(
      (op: AnimalHealthOperationDto) =>
        op.type.includes('annual-reminder') && dayjs(op.date, 'DD/MM/YYYY').isAfter(dayjs())
    )
    if (operation) {
      return operation
    }
  }

  const nextDewormingOperation = () => {
    const operation = animal.health.operations?.find(
      (op: AnimalHealthOperationDto) => op.type.includes('deworming') && dayjs(op.date, 'DD/MM/YYYY').isAfter(dayjs())
    )
    if (operation) {
      return operation
    }
  }

  const nextFleaControlOperation = () => {
    const operation = animal.health.operations?.find(
      (op: AnimalHealthOperationDto) =>
        op.type.includes('flea-control') && dayjs(op.date, 'DD/MM/YYYY').isAfter(dayjs())
    )
    if (operation) {
      return operation
    }
  }

  const getDefaultValues = (attempt: AdoptionAttemptDto): FormParams => ({
    offerType: animal.adoption.offerType || undefined,
    priceInEuros: animal.adoption.priceInEuros || undefined,
    paymentDone: attempt?.paymentDone ? 'true' : 'false',
    needed: attempt?.sterilization?.needed === true ? 'true' : 'false',
    depositAmount: attempt?.sterilization?.depositAmount || 0,
    depositStatus: attempt?.sterilization?.depositStatus,
    date: attempt?.sterilization?.date || '',
    exitDate: animal.adoption.exitDate || '',
    adoptionDate: animal.adoption.adoptionDate || '',
    vpaDate: animal.adoption.vpaDate || '',
    newName: animal.adoption.newName || '',
    vaccinationStatus: animal.vaccination.vaccinationStatus || undefined,
    nextVaccinationDate: nextVaccinationOperation()?.date || '',
    nextDewormingDate:
      animal.health.operations?.find((op: AnimalHealthOperationDto) => op.type.includes('deworming'))?.date || '',
    nextFleaControlDate:
      animal.health.operations?.find((op: AnimalHealthOperationDto) => op.type.includes('flea-control'))?.date || '',
  })

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useFormExtended(attempt, getDefaultValues)

  const isDonation = watch('offerType') === AnimalAdoptionDtoOfferType.Donation
  useEffect(() => {
    if (isDonation) {
      setValue('priceInEuros', 0, { shouldValidate: true })
    }
  }, [isDonation, setValue])

  const handleGenerateLink = async () => {
    if (!animal.adoption.priceInEuros) return
    let paymentLink = attempt.paymentLink
    if (animal.adoption.priceInEuros && !attempt.paymentLink) {
      const response = await backendClient.post('/stripe/create-adoption-payment-link', {
        adopterId: adopterId,
        price: (Number(animal.adoption.priceInEuros) + 12) * 100,
        attemptId: attempt?.id as string,
      })
      paymentLink = response.data
      setAdoptionAttempt({ ...attempt, paymentLink: response.data })
    }
    const price = Number(animal.adoption.priceInEuros) + 12
    const body = `Bonjour,

Vous adoptez ${animal.name} auprès de notre association, merci !
Comme évoqué, l'adoption implique le règlement de frais, fixés à ${price}€, afin de couvrir les soins apportés à ${animal.name} ainsi que les frais de fonctionnement de notre association et nos partenaires.

Dans ce cadre, je vous invite à régler les frais d’adoption en ligne via notre partenaire Petso. Cet outil nous aide à suivre nos animaux et à gérer efficacement notre association. Voici le lien pour effectuer le paiement :
${paymentLink}

Si vous préférez un autre mode de paiement, n’hésitez pas à nous contacter. Merci pour votre compréhension et votre engagement en faveur de nos animaux !\n

`
    window.open(`mailto:${adopterEmail}?subject=Réglement des frais d'adoption&body=${encodeURI(body)}`, '_blank')
  }

  const onSubmit = async (data: FormParams) => {
    setIsLoading(true)

    let operations = animal.health.operations || []

    if (data.nextVaccinationDate && data.nextVaccinationDate !== nextVaccinationOperation()?.date) {
      const date = dayjs(data.nextVaccinationDate).format('DD/MM/YYYY')
      const operation = nextVaccinationOperation()
      if (operation) {
        operation.date = date
        operations = operations.map((op) => (op.id === operation.id ? operation : op))
      } else {
        operations.push({
          id: nanoid(),
          type: ['annual-reminder'],
          date: date,
        })
      }
    }

    if (data.nextDewormingDate && data.nextDewormingDate !== nextDewormingOperation()?.date) {
      const date = dayjs(data.nextDewormingDate).format('DD/MM/YYYY')
      const operation = nextDewormingOperation()
      if (operation) {
        operation.date = date
        operations = operations.map((op) => (op.id === operation.id ? operation : op))
      } else {
        operations.push({
          id: nanoid(),
          type: ['deworming'],
          date: date,
        })
      }
    }

    if (data.nextFleaControlDate && data.nextFleaControlDate !== nextFleaControlOperation()?.date) {
      const date = dayjs(data.nextFleaControlDate).format('DD/MM/YYYY')
      const operation = nextFleaControlOperation()
      if (operation) {
        operation.date = date
        operations = operations.map((op) => (op.id === operation.id ? operation : op))
      } else {
        operations.push({
          id: nanoid(),
          type: ['flea-control'],
          date: date,
        })
      }
    }

    for (let i = 0; i < files.length; i++) {
      if (files[i] !== null) {
        const formData = new FormData()
        formData.append('file', files[i] as File)
        formData.append(
          'type',
          i === 0 ? 'information-document' : i === 1 ? 'sell-certificate' : 'veterinary-certificate'
        )
        await AdoptersClient.addDocumentToAdoptionAttempt(adopterId, attempt.id, formData)
      }
    }

    const updatedAnimal = {
      ...animal,
      adoption: {
        ...animal.adoption,
        exitDate: data.exitDate,
        adoptionDate: data.adoptionDate,
        vpaDate: data.vpaDate,
        newName: data.newName,
        offerType: data.offerType,
        priceInEuros: data.priceInEuros,
        status: AnimalAdoptionDtoStatus.HasBeenAdopted,
        adopterId: adopterId,
        step: AnimalAdoptionDtoStep.AdoptionValidatedWithoutPostVisit,
      },
      vaccination: {
        ...animal.vaccination,
        vaccinationStatus: data.vaccinationStatus || undefined,
      },
      health: {
        ...animal.health,
        operations: operations,
      },
    }
    const returnedAnimal = await AnimalsClient.editAnimal(updatedAnimal)
    setAnimal(returnedAnimal)
    if (data.paymentDone !== attempt.paymentDone.toString()) {
      if (data.paymentDone === 'true' && attempt.paymentDone === false) {
        attempt.events.push({ id: nanoid(), type: 'payment-reception', date: new Date().toISOString() })
      }
      if (data.paymentDone === 'false' && attempt.paymentDone === true) {
        attempt.events.push({ id: nanoid(), type: 'payment-cancelled', date: new Date().toISOString() })
      }
    }
    attempt.paymentDone = data.paymentDone === 'true'
    attempt.sterilization = {
      needed: data.needed === 'true',
      depositAmount: data.depositAmount,
      depositStatus: data.depositStatus as AdoptionAttemptDtoSterilizationDepositStatus,
      date: data.date,
    }
    attempt.done = true
    attempt.archived = true

    await updateAttemptMutation.mutateAsync(attempt)

    setIsLoading(false)
    navigate(`/suivi-adoptant/${adopterId}/?tab=past-adoption&attemptId=${attempt.id}`)
    setOpen(false)
  }

  const adoptionDate = watch('adoptionDate')
  const exitDatesDisabled = adoptionDate === '' || adoptionDate == null

  return (
    <Dialog onClose={() => setOpen(false)} open={open} maxWidth="lg">
      <DialogContent>
        <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={() => setOpen(false)}>
          <Close />
        </IconButton>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            mb: 3,
            textAlign: 'left',
            maxWidth: '100%',
            width: '1200px',
          }}
        >
          <ReportGmailerrorredOutlined sx={{ color: colors.cello, fontSize: '3rem' }} />
          <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h4" sx={{ color: colors.blueSky, fontWeight: 'bold', fontSize: 24 }}>
              Adoption de {animal.name} terminée
            </Typography>
            <Typography variant="body1" sx={{ color: colors.blue, fontWeight: 'bold', fontSize: 14 }}>
              Même après l’adoption, complétez les caractéristiques et le suivi de santé de {animal.name} pour que
              l’adoptant puisse y avoir accès sur son espace
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2} component="form" onSubmit={handleSubmit(onSubmit)} id="end-adoption-form">
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: colors.cello, fontWeight: 'bold', fontSize: 20 }}>
              Suivi des frais d&apos;adoption
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <ControlledSelectField
              control={control}
              error={errors.offerType}
              fieldName="offerType"
              label="Nature de l'offre"
              options={Object.values(AnimalAdoptionDtoOfferType).map((field: AnimalAdoptionDtoOfferType) => ({
                label: getReadableAnimalAdoptionOfferType(field),
                value: field,
              }))}
              requiredRule=""
              size="small"
            />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <TextField
              id="price-input"
              label="Montant"
              type="number"
              inputProps={{
                step: '0.01',
                inputMode: 'decimal',
                pattern: '[0-9]*',
              }}
              disabled={isDonation}
              fullWidth
              {...register('priceInEuros')}
              InputProps={{
                endAdornment: <InputAdornment position="end">€</InputAdornment>,
              }}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>

          <Grid item xs={6} md={3}>
            <ColoredChipSelectField
              control={control}
              error={errors.paymentDone}
              fieldName="paymentDone"
              label="Status"
              options={[
                { label: 'Validé', value: 'true', color: colors.seaGreen },
                { label: 'En attente', value: 'false', color: colors.amber },
              ]}
              requiredRule=""
              size="small"
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Tooltip
              title={
                animal.adoption.priceInEuros ? '' : 'Veuillez définir un montant avant de générer le lien de paiement'
              }
            >
              <span>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleGenerateLink}
                  sx={{
                    width: '100%',
                    fontWeight: 'bold',
                    textTransform: 'none',
                    borderRadius: 4,
                    marginBottom: 1,
                  }}
                  disabled={!animal.adoption.priceInEuros}
                >
                  Transmettre le lien de paiement
                </Button>
              </span>
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: colors.cello, fontWeight: 'bold', fontSize: 20 }}>
              Suivi de la stérilisation
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <ColoredChipSelectField
              control={control}
              error={errors.needed}
              fieldName="needed"
              label="Stérilisation par l'adoptant"
              options={[
                { label: 'Oui', value: 'true', color: colors.seaGreen },
                { label: 'Non', value: 'false', color: colors.amber },
              ]}
              requiredRule=""
              size="small"
              sx={{ mb: 2 }}
            />
          </Grid>
          {watch('needed') === 'false' ? (
            <Grid item xs={12} sm={6} md={9}>
              <Typography variant="body1" sx={{ color: colors.blue, fontSize: 14, mt: 1 }}>
                Si oui, transmettez la date de stérilisation prévue. L’adoptant vous transmettra via Petso le
                justificatif
              </Typography>
            </Grid>
          ) : (
            <>
              <Grid item xs={6} sm={6} md={3}>
                <TextField
                  id="price-input"
                  label="Montant de la caution"
                  type="number"
                  inputProps={{
                    step: '0.01',
                    inputMode: 'decimal',
                    pattern: '[0-9]*',
                  }}
                  fullWidth
                  {...register('depositAmount')}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  }}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  sx={{ mb: 2 }}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3}>
                <ControlledDateField
                  control={control}
                  error={errors.date}
                  fieldName="date"
                  label="Date de stérilisation prévue"
                  requiredRule=""
                  size="small"
                  validate={(value) => isValidPastDateCoherence(value) || value === null}
                  sx={{ mb: 2 }}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={3}>
                <ColoredChipSelectField
                  control={control}
                  error={errors.depositStatus}
                  fieldName="depositStatus"
                  label="Etat de la caution"
                  options={Object.values(AdoptionAttemptDtoSterilizationDepositStatus).map((status) => ({
                    label: getReadableDepositStatus(status),
                    value: status,
                    color: getColorForDepositStatus(status),
                  }))}
                  requiredRule=""
                  size="small"
                  sx={{ mb: 2, mt: 0.4 }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: colors.cello, fontWeight: 'bold', fontSize: 20 }}>
              Antécédents de santé et échéances à venir
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <ColoredChipSelectField
              control={control}
              error={errors.vaccinationStatus}
              fieldName="vaccinationStatus"
              size="small"
              label="Statut primovaccination"
              options={Object.values(CreateAnimalBodyDtoVaccinationStatus).map(
                (status: CreateAnimalBodyDtoVaccinationStatus) => ({
                  label: getReadableAnimalVaccineStatus(status),
                  value: status,
                  color: getColorForAnimalVaccineStatus(status),
                })
              )}
              requiredRule="Le statut primovaccination est requis"
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ControlledDateField
              control={control}
              validate={(value) => isValidPastDateCoherence(value) || value === null}
              error={undefined}
              fieldName={'nextVaccinationDate'}
              label="Prochain vaccin"
              requiredRule={undefined}
              sx={{ marginTop: 0 }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ControlledDateField
              control={control}
              validate={(value) => isValidPastDateCoherence(value) || value === null}
              error={undefined}
              fieldName={'nextDewormingDate'}
              label="Prochain vermifuge"
              requiredRule={undefined}
              sx={{ marginTop: 0 }}
              size="small"
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <ControlledDateField
              control={control}
              validate={(value) => isValidPastDateCoherence(value) || value === null}
              error={undefined}
              fieldName={'nextFleaControlDate'}
              label="Prochain antiparasitaire"
              requiredRule={undefined}
              sx={{ marginTop: 0 }}
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: colors.cello, fontWeight: 'bold', fontSize: 20 }}>
              Informations de sortie
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3}>
            <ControlledDateField
              control={control}
              validate={(value) => isValidPastDateCoherence(value) || value === null}
              // error={errors.adoptionDate}
              error={undefined}
              fieldName={'adoptionDate'}
              label="Adoption (physique)"
              requiredRule={undefined}
              sx={{ marginTop: 0 }}
              size="small"
              maxDate={dayjs()}
            />
          </Grid>

          <Grid item xs={12} sm={3}>
            <Tooltip title={exitDatesDisabled ? "Veuillez d'abord remplir la date d'adoption" : ''}>
              <span>
                <ControlledDateField
                  control={control}
                  validate={(value) => isValidPastDateCoherence(value) || value === null}
                  // error={errors.adoptionDate}
                  error={undefined}
                  fieldName={'vpaDate'}
                  label="Visite post-adoption"
                  requiredRule={undefined}
                  sx={{ marginTop: 0 }}
                  size="small"
                  minDate={dayjs(adoptionDate, 'DD/MM/YYYY')}
                  disabled={exitDatesDisabled}
                />
              </span>
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={3}>
            <Tooltip title={exitDatesDisabled ? "Veuillez d'abord remplir la date d'adoption" : ''}>
              <span>
                <ControlledDateField
                  control={control}
                  validate={(value) => isValidPastDateCoherence(value) || value === null}
                  error={errors.exitDate}
                  fieldName={'exitDate'}
                  label="Date de sortie (Cession I-CAD)"
                  requiredRule={undefined}
                  sx={{ marginTop: 0 }}
                  size="small"
                  minDate={dayjs(adoptionDate, 'DD/MM/YYYY')}
                  disabled={exitDatesDisabled}
                />
              </span>
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={3}>
            <TextField
              id="new-name-input"
              label="Nouveau nom"
              type="text"
              fullWidth
              {...register('newName')}
              size="small"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: colors.cello, fontWeight: 'bold', fontSize: 20 }}>
              Documents
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div
              {...dropzoneForInfoDoc.getRootProps()}
              style={{
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography
                component="span"
                sx={{ textTransform: 'none', fontSize: 18, fontWeight: 'bold', width: 'fit-content' }}
              >
                <input {...dropzoneForInfoDoc.getInputProps()} />
                Document d&apos;information
                <span
                  style={{ color: colors.blueSky, cursor: 'pointer' }}
                  onClick={(e) => {
                    if (files[0] === null) return
                    e.stopPropagation()
                    setFiles((files) => [null, files[1], files[2]])
                  }}
                >
                  {files[0]?.name
                    ? ' - retirer'
                    : dropzoneForInfoDoc.isDragActive
                    ? ' - déposez le fichier ici'
                    : ' - ajouter'}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 14 }}>
                {files[0]?.name ?? ''}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div
              {...dropzoneForAttestation.getRootProps()}
              style={{
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography
                component="span"
                sx={{ textTransform: 'none', fontSize: 18, fontWeight: 'bold', width: 'fit-content' }}
              >
                <input {...dropzoneForAttestation.getInputProps()} />
                Facture/Attestion de cession
                <span
                  style={{ color: colors.blueSky, cursor: 'pointer' }}
                  onClick={(e) => {
                    if (files[1] === null) return
                    e.stopPropagation()
                    setFiles((files) => [files[0], null, files[2]])
                  }}
                >
                  {files[1]?.name
                    ? ' - retirer'
                    : dropzoneForAttestation.isDragActive
                    ? ' - déposez le fichier ici'
                    : ' - ajouter'}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 14 }}>
                {files[1]?.name ?? ''}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div
              {...dropzoneForVeterinaryCert.getRootProps()}
              style={{
                marginBottom: '20px',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Typography
                component="span"
                sx={{ textTransform: 'none', fontSize: 18, fontWeight: 'bold', width: 'fit-content' }}
              >
                <input {...dropzoneForVeterinaryCert.getInputProps()} />
                Certificat vétérinaire
                <span
                  style={{ color: colors.blueSky, cursor: 'pointer' }}
                  onClick={(e) => {
                    if (files[2] === null) return
                    e.stopPropagation()
                    setFiles((files) => [files[0], files[1], null])
                  }}
                >
                  {files[2]?.name
                    ? ' - retirer'
                    : dropzoneForVeterinaryCert.isDragActive
                    ? ' - déposez le fichier ici'
                    : ' - ajouter'}
                </span>
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 14 }}>
                {files[2]?.name ?? ''}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setOpen(false)}
            sx={{ fontSize: 16, fontWeight: 'bold', textTransform: 'none' }}
          >
            Plus tard
          </Button>
          <LoadingButton
            variant="contained"
            color="secondary"
            type="submit"
            form="end-adoption-form"
            disabled={isLoading}
            loading={isLoading}
            sx={{ fontSize: 16, fontWeight: 'bold', textTransform: 'none' }}
          >
            {`Marquer l'adoption comme terminée`}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
