import { Box, Card, CardContent, Chip, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import {
  getColorForAdopterProfileHasBeenAccepted,
  getReadableAdopterProfileHasBeenAccepted,
} from '../../domain/Adopters/AccountDisplay'
import { AdopterDto } from '../../interactors/gen/backendClient'
import { useAccountsStore } from '../../store/AccountsStore'
import { isColorDark } from '../../utils/isColorDark'
import { blueSky, theme } from '../theme'
import { AddAdoptionPopUp } from './AddAdoptionPopUp'
import { TabKey } from './AdopterDetailsScreen'
import { EditAdopterGeneralInfoPrompt } from './EditAdopterGeneralInfoPrompt'

interface Props {
  adopter: AdopterDto
  isOwnAccount: boolean
  onSubmit: (data: Partial<AdopterDto>) => Promise<void>
  isLoading: boolean
  changeTab: (_: React.SyntheticEvent, newTabKey: TabKey) => void
  sendEmailToAdopter: boolean
  setSendEmailToAdopter: (value: boolean) => void
}

export const AdopterGeneralInfo: React.FC<Props> = ({
  adopter,
  isOwnAccount,
  onSubmit,
  isLoading,
  sendEmailToAdopter,
  setSendEmailToAdopter,
}) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const accountsStore = useAccountsStore()

  /* const sendFormMutation = useMutation(
    async () => {
      const config = {
        method: 'POST',
        url: 'adopters-platform/auth/create-password/request',
        data: {
          adopterId: adopter.id,
          customerId: adopter.customerId,
        },
      }
      const response = await backendClient(config)
      return response
    },
    {
      onSuccess: () => {
        globalSnackBarStore.triggerSuccessMessage(`Le formulaire a été envoyé par email à ${adopter.firstName}!`)
        adopter.profileHasBeenAccepted = AdopterDtoProfileHasBeenAccepted.FormSent
      },
    }
  ) */
  return (
    <Card
      sx={{
        mt: { xs: 'auto', sm: 2 },
        px: { xs: 'auto', sm: 4 },
      }}
    >
      <CardContent sx={{ '&:last-child': { pb: isMobile ? '16px' : 'auto' } }}>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          justifyContent="space-between"
          gap={isMobile ? 2 : 0}
        >
          {/* 1st Box: Edit, coords, profile */}
          <Box display="flex" flexDirection={isMobile ? 'row-reverse' : 'row'}>
            {/* Edit button */}
            {accountsStore.canEdit() && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  width: isMobile ? '30px' : '40px',
                  borderRight: isMobile ? 'none' : '2px solid black',
                  borderLeft: isMobile ? '2px solid black' : 'none',
                  borderRightColor: 'grey.200',
                  borderLeftColor: 'grey.200',
                  mr: isMobile ? 0 : 4,
                }}
              >
                <EditAdopterGeneralInfoPrompt
                  adopter={adopter}
                  isOwnAccount={isOwnAccount}
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                />
              </Box>
            )}
            {/* Coords, profile */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'row-reverse', sm: 'column' },
                justifyContent: 'space-between',
                width: '100%',
                fontSize: '16px',
                gap: { xs: 2, sm: 0.8 },
              }}
            >
              {/* Coords */}
              <Box display="flex" flexDirection="column" gap={isMobile ? 0 : 0.8}>
                {adopter?.email && (
                  <Typography variant="body2" color="text.secondary">
                    {adopter?.email}
                  </Typography>
                )}
                {adopter?.phoneNumber && (
                  <Typography variant="body2" color="text.secondary">
                    {adopter?.phoneNumber}
                  </Typography>
                )}
                <Typography variant="body2" color="text.secondary">
                  {adopter.address && adopter.postalCode && adopter.city
                    ? `${adopter?.address}, ${adopter?.postalCode} ${adopter?.city}`
                    : 'Adresse non renseignée'}
                </Typography>
              </Box>
              {/* Profile + chip */}
              <Box>
                <Box
                  display="flex"
                  flexDirection={isMobile ? 'column' : 'row'}
                  alignItems={isMobile ? 'left' : 'center'}
                  gap={isMobile ? 1 : 1}
                  pt={isMobile ? 0 : 1}
                >
                  <Typography variant="body2" color="text.secondary" sx={{ display: 'inline-block' }}>
                    Etat du profil:{' '}
                  </Typography>
                  <Chip
                    label={
                      adopter.profileHasBeenAccepted
                        ? getReadableAdopterProfileHasBeenAccepted(adopter.profileHasBeenAccepted)
                        : '..'
                    }
                    sx={{
                      backgroundColor: adopter.profileHasBeenAccepted
                        ? getColorForAdopterProfileHasBeenAccepted(adopter.profileHasBeenAccepted)
                        : blueSky,
                      color:
                        adopter.profileHasBeenAccepted &&
                        isColorDark(getColorForAdopterProfileHasBeenAccepted(adopter.profileHasBeenAccepted))
                          ? 'white'
                          : 'black',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          {/* 2nd Box: Button: form / add adoption */}
          <Box display="flex" flexDirection="column" justifyContent="center">
            <AddAdoptionPopUp
              adopter={adopter}
              sendEmailToAdopter={sendEmailToAdopter}
              setSendEmailToAdopter={setSendEmailToAdopter}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}
