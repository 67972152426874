import { Box, styled } from '@mui/material'
import React, { useState } from 'react'
import { theme } from '../../theme'
import { FeaturesList } from './FeaturesList'

const TabContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '0.5rem',
  backgroundColor: theme.palette.background.default,
  zIndex: 2,
  position: 'relative',
  boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  borderRadius: '30px',
  padding: '10px',
})

const TabButton = styled(Box)(({ selected }: { selected: boolean }) => ({
  padding: '0.75rem 1rem',
  borderRadius: '20px',
  fontWeight: 600,
  color: selected ? 'white' : theme.palette.secondary.main,
  backgroundColor: selected ? theme.palette.secondary.main : theme.palette.background.default,
  boxShadow: selected ? 'inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25)' : 'none',
  cursor: 'pointer',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: selected ? theme.palette.secondary.light : theme.palette.action.hover,
  },
  whiteSpace: 'nowrap',
}))

const HorizontalLine = styled(Box)({
  width: '100%',
  height: '2px',
  backgroundColor: theme.palette.secondary.main,
  zIndex: 1,
})

const ContentCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: theme.palette.background.paper,
  borderRadius: '8px',
  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
  padding: '1.5rem',
}))

interface Props {
  isSubscribed: boolean
}

const OfferTabs: React.FC<Props> = ({ isSubscribed }) => {
  const [selectedTab, setSelectedTab] = useState<'free' | 'premium'>(isSubscribed ? 'premium' : 'free')

  return (
    <Box sx={{ maxWidth: '100%', margin: 'auto', textAlign: 'center', mt: 4 }}>
      <Box
        sx={{
          width: '100%',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <HorizontalLine />
        <TabContainer>
          <TabButton selected={selectedTab === 'free'} onClick={() => setSelectedTab('free')}>
            Offre gratuite
          </TabButton>
          <TabButton selected={selectedTab === 'premium'} onClick={() => setSelectedTab('premium')}>
            48€/Mois TTC
          </TabButton>
        </TabContainer>
        <HorizontalLine />
      </Box>

      <ContentCard sx={{ mt: 4, flexDirection: { md: 'row', xs: 'column' } }}>
        <Box sx={{ width: { md: '50%', xs: '100%' } }}>
          <FeaturesList title="Suivi sanitaire et administratif">
            <ol
              style={{ paddingLeft: '56px', color: theme.palette.secondary.main, textAlign: 'left', fontWeight: 500 }}
            >
              <li>Création et édition de la fiche animale détaillée</li>
              <li>Suivi et rappel automatique des soins et rendez-vous</li>
              <li>
                Historique de santé{' '}
                <span
                  style={{
                    color: selectedTab === 'free' ? 'grey' : 'inherit',
                    textDecoration: selectedTab === 'free' ? 'line-through' : 'none',
                    fontWeight: selectedTab === 'free' ? 400 : 600,
                  }}
                >
                  et des déplacements
                </span>
              </li>
            </ol>
          </FeaturesList>

          <FeaturesList title="Suivi des adoptions">
            <ol
              style={{ paddingLeft: '56px', color: theme.palette.secondary.main, textAlign: 'left', fontWeight: 500 }}
            >
              <li>Création de vos modèles (certificats et contrats)</li>
              <li>facultatif : signatures éléctroniques des documents</li>
              <li>facultatif : réglements des frais d’adoption en ligne</li>
            </ol>
          </FeaturesList>

          <FeaturesList title="Gestion des membres">
            <ol
              style={{ paddingLeft: '56px', color: theme.palette.secondary.main, textAlign: 'left', fontWeight: 500 }}
            >
              <li style={{ fontWeight: selectedTab === 'free' ? 400 : 600 }}>
                {selectedTab === 'free'
                  ? `Espace limitée à 10 licences selon 4 permissions d’accès`
                  : 'Nombre de licences illimitées selon 4 permissions d’accès'}
              </li>
              <li>Assignation des animaux à un responsable/une FA</li>
              <li>
                <span
                  style={{
                    color: selectedTab === 'free' ? 'grey' : 'inherit',
                    textDecoration: selectedTab === 'free' ? 'line-through' : 'none',
                    fontWeight: selectedTab === 'free' ? 400 : 600,
                  }}
                >
                  Gestion des donateurs et des adhérents & reçus fiscaux
                </span>
              </li>
            </ol>
          </FeaturesList>
        </Box>

        <Box sx={{ width: { md: '50%', xs: '100%' } }}>
          <FeaturesList title="Gestion des annonces">
            <ol
              style={{ paddingLeft: '56px', color: theme.palette.secondary.main, textAlign: 'left', fontWeight: 500 }}
            >
              <li>Gestion des statuts d’adoption</li>
              <li>Création de votre page association sur Petso.fr</li>
              <li>Publication automatiques auprès de sites partenaires</li>
            </ol>
          </FeaturesList>

          <FeaturesList title="Création d’un carnet de santé digitale pour vos adoptants">
            <ol
              style={{ paddingLeft: '56px', color: theme.palette.secondary.main, textAlign: 'left', fontWeight: 500 }}
            >
              <li>Transmission du profil de l’animal et des rappels à venir</li>
              <li>Option de rappel et validation de la stérilisation</li>
              <li>Conseils & recommandations post-adoption</li>
            </ol>
          </FeaturesList>

          <FeaturesList title="Exports et suivi global">
            <ol
              style={{ paddingLeft: '56px', color: theme.palette.secondary.main, textAlign: 'left', fontWeight: 500 }}
            >
              <li>
                <span
                  style={{
                    color: selectedTab === 'free' ? 'grey' : 'inherit',
                    textDecoration: selectedTab === 'free' ? 'line-through' : 'none',
                    fontWeight: selectedTab === 'free' ? 400 : 600,
                  }}
                >
                  Génération du E/S, sanitaire et bons vétérinaires
                </span>
              </li>
              <li>
                <span
                  style={{
                    color: selectedTab === 'free' ? 'grey' : 'inherit',
                    textDecoration: selectedTab === 'free' ? 'line-through' : 'none',
                    fontWeight: selectedTab === 'free' ? 400 : 600,
                  }}
                >
                  Tableau de bord et statistiques
                </span>
              </li>
            </ol>
          </FeaturesList>
        </Box>
      </ContentCard>
    </Box>
  )
}

export default OfferTabs
