import { LoadingButton } from '@mui/lab'
import { Box, Button, Dialog, DialogActions, DialogContent, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { fullName } from '../../domain/Adopters/AccountDisplay'
import { getReadableAnimalAdoptionStatus } from '../../domain/Animal/AnimalDisplay'
import { AnimalsClient } from '../../interactors/clients/AnimalsClient'
import {
  AdopterDto,
  AdoptionAttemptDto,
  AnimalAdoptionDtoStatus,
  AnimalAdoptionDtoStep,
  AnimalDto,
} from '../../interactors/gen/backendClient'
import { useAdoptersStore } from '../../store/AdoptersStore'
import { useGlobalSnackbarStore } from '../../store/GlobalSnackBarStore'
import { useDialogOpened } from '../../utils/hooks/useDialogOpened'
import { AdoptersClient } from '../../interactors/clients/AdoptersClient'
import { AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { readableAxiosError } from '../../utils/axios'
import { theme } from '../theme'

interface Props {
  animalId: string | null
}

export const NewAdoptionAttemptConfirmModal: React.FC<Props> = ({ animalId }) => {
  const queryParams = new URLSearchParams(location.search)
  const adopterId = queryParams.get('adopterId')
  const sendEmailToAdopter = queryParams.get('sendEmailToAdopter')

  const { isDialogOpen, openDialog, closeDialog } = useDialogOpened()
  const navigate = useNavigate()
  const globalSnackBarStore = useGlobalSnackbarStore()
  const adoptersStore = useAdoptersStore()
  const [animal, setAnimal] = useState<AnimalDto | null>(null)
  const [animalAdopter, setAnimalAdopter] = useState<AdopterDto | undefined>(undefined)
  const [lockState, setLockState] = useState(false)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const prevAnimalId = useRef<string | null>(animalId)

  useEffect(() => {
    if (animalId === prevAnimalId.current) {
      return
    }

    if (animalId) {
      const loadAnimal = async () => {
        await AnimalsClient.getAnimal(animalId)
          .then((animal) => {
            if (
              animal.adoption.adopterId &&
              adoptersStore.adopters
                .find((adopter) => adopter.id === animal.adoption.adopterId)
                ?.adoptionAttempts?.some((attempt) => attempt.animalId === animal.id)
            ) {
              setAnimalAdopter(adoptersStore.adopters.find((adopter) => adopter.id === animal.adoption.adopterId))
            }
            setAnimal(animal)
          })
          .catch((_error) => {
            globalSnackBarStore.triggerErrorMessage(`L'animal n'a pas été trouvé.`)
          })
      }
      loadAnimal()
    }

    prevAnimalId.current = animalId
  }, [animalId])

  const newAdoptionAttemptMutation = useMutation(
    async ({ adopterId, animalId }: { adopterId: string; animalId: string }) => {
      const response: AdoptionAttemptDto = await AdoptersClient.newAdoptionAttempt(
        adopterId,
        animalId,
        sendEmailToAdopter === 'true'
      )
      return response
    },
    {
      onSuccess: (data) => {
        adoptersStore.fetchOrRefreshAdopters()
        navigate(`/suivi-adoptant/${queryParams.get('adopterId')}/?tab=ongoing-adoption&attemptId=${data.id}`)
        globalSnackBarStore.triggerSuccessMessage(`Nouvelle procédure d'adoption créée avec succès.`)
      },
      onError: (error: Error | AxiosError) => {
        globalSnackBarStore.triggerErrorMessage(readableAxiosError(error).join(' '))
      },
    }
  )

  const onSubmit = () => {
    setLockState(true)
    if (adopterId && animalId) {
      if (adopter?.adoptionAttempts?.find((attempt) => attempt.animalId === animalId)) {
        return globalSnackBarStore.triggerErrorMessage(`L'adoptant a déjà une procédure d'adoption pour cet animal.`)
      }
      if (animalAdopter) {
        animalAdopter.adoptionAttempts?.forEach((attempt) => {
          if (attempt.animalId === animalId) {
            attempt.status = 'suspended-by-customer'
            AdoptersClient.updateAdoptionAttempt(animalAdopter.id, attempt)
          }
        })
      }
      setAnimalAdoptionStatusToInTheProcessOfBeingAdoptable()
      newAdoptionAttemptMutation.mutate({ adopterId: adopterId, animalId: animalId })
    } else {
      globalSnackBarStore.triggerErrorMessage(`L'adoptant n'a pas été trouvé.`)
    }
    closeDialog()
  }

  const setAnimalAdoptionStatusToInTheProcessOfBeingAdoptable = () => {
    if (animal && animal.adoption.status !== AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable) {
      animal.adoption.status = AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable
      animal.adoption.step = AnimalAdoptionDtoStep.AdoptionToBeValidated
      animal.adoption.adoptionDate = undefined
      animal.adoption.exitDate = undefined
      animal.adoption.vpaDate = undefined
      if (adopterId) {
        animal.adoption.adopterId = adopterId
      }
      AnimalsClient.editAnimal(animal)
    }
  }

  useEffect(() => {
    if (animalId) {
      openDialog()
    }
  }, [animalId])

  const adopter = adoptersStore.adopters.find((anAdopter) => anAdopter.id === adopterId)

  return (
    <Dialog
      open={isDialogOpen}
      onClose={closeDialog}
      PaperProps={{
        style: { borderRadius: 15, padding: 15 },
      }}
    >
      <DialogContent sx={{ pb: isMobile ? 0 : 'auto' }}>
        {animal && (
          <Box>
            <Typography variant="h4" component="h2" marginBottom={4} fontSize={20}>
              {`Vous avez sélectionné ${animal.name} comme étant le souhait d'adoption de ${
                adopter && fullName(adopter)
              }`}
            </Typography>
            {(lockState || animal.adoption.status !== AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable) && (
              <Typography variant="body2" component="p" marginBottom={4} fontSize={15} color="black">
                <span style={{ fontWeight: 700 }}>
                  <Link style={{ color: 'black' }} to={`/animaux/${animal.id}`}>
                    {animal.name}
                  </Link>{' '}
                  {` est actuellement enregistré comme: ${
                    animal.adoption.status ? `"${getReadableAnimalAdoptionStatus(animal.adoption.status)}"` : 'Erreur'
                  }.`}
                </span>
                <br />
                {`Souhaitez vous confirmer le souhait d'adoption ? Si oui il sera alors considéré comme "En cours d'adoption" et directement associé à la nouvelle procédure d'adoption de ${
                  adopter ? fullName(adopter) : ''
                }.`}
              </Typography>
            )}
            {animalAdopter && animalAdopter.id !== adopter?.id && (
              <Typography variant="body2" component="p" marginBottom={4} fontSize={15} color="black">
                <span style={{ fontWeight: 700 }}>
                  <Link style={{ color: 'black' }} to={`/animaux/${animal.id}`}>
                    {animal.name}
                  </Link>{' '}
                  {` a déjà une procédure d'adoption lancée avec: ${fullName(animalAdopter)}`}
                </span>
                <br />
                {`Si vous continuez, cette procédure sera suspendue et une nouvelle procédure sera créée avec ${
                  adopter ? fullName(adopter) : ''
                }.`}
              </Typography>
            )}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          width="100%"
          gap={1}
          justifyContent={isMobile ? 'auto' : 'flex-end'}
        >
          <Button
            variant="contained"
            sx={{
              width: isMobile ? '100%' : 'auto',
              textTransform: 'none',
              fontSize: isMobile ? '14px' : '16px',
              px: isMobile ? 'auto' : '50px',
            }}
            onClick={() => closeDialog()}
          >
            Annuler
          </Button>
          <LoadingButton
            data-testid="apply-changes"
            variant="contained"
            sx={{ width: isMobile ? '100%' : 'auto', textTransform: 'none', fontSize: isMobile ? '14px' : '16px' }}
            onClick={() => onSubmit()}
          >
            {lockState ||
            animal?.adoption.status !== AnimalAdoptionDtoStatus.InTheProcessOfBeingAdoptable ||
            (animalAdopter && animalAdopter.id !== adopter?.id)
              ? 'Valider quand même'
              : 'Valider'}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
