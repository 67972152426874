import { Close, Info } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import jsPDF from 'jspdf'
import { matchIsValidTel, MuiTelInput } from 'mui-tel-input'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { Link } from 'react-router-dom'
import {
  getColorForAnimalVaccineStatus,
  getReadableAnimalAdoptionOfferType,
  getReadableAnimalVaccineStatus,
  getReadableSpecies,
} from '../../../domain/Animal/AnimalDisplay'
import { catBreeds, dogBreeds } from '../../../domain/Animal/Breeds'
import { translateVariablePlaceholder } from '../../../domain/Customer/TemplateVariables'
import { AdoptersClient } from '../../../interactors/clients/AdoptersClient'
import { AnimalsClient } from '../../../interactors/clients/AnimalsClient'
import { backendClient } from '../../../interactors/clients/client'
import { CustomersClient } from '../../../interactors/clients/CustomerClient'
import {
  AdopterDto,
  AnimalAdoptionDtoOfferType,
  AnimalDto,
  AnimalDtoSex,
  AnimalDtoSpecies,
  CreateAnimalBodyDtoVaccinationStatus,
  CustomerDto,
} from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { blobToFile } from '../../../utils/blobToFile'
import { isValidPastDateCoherence } from '../../../utils/date/isValidPastDateCoherence'
import { setOpacity } from '../../../utils/setOpacity'
import { ColoredChipSelectField } from '../../common/ColoredChipSelectField'
import { ControlledAutoCompleteWithCategories } from '../../common/ControlledAutocompleteWithCategories'
import { ControlledAutoCompleteWithCustomValue } from '../../common/ControlledAutocompleteWithCustomValue'
import { ControlledDateField } from '../../common/ControlledDateField'
import { ControlledSelectField } from '../../common/ControlledSelectField'
import { InfoBox } from '../../common/InfoBox'
import { blue, colors, spacingItem, theme } from '../../theme'
import { VariableFormType } from '../AdopterOnGoingAdoptions/AddAdoptionDocumentModal'

interface Props {
  submitStep: (data: {
    animalId?: string
    files?: File[]
    deliverLater?: boolean
    isLocked?: boolean
    certificateTemplateKey?: string
    contractTemplateKey?: string
    animalPrice?: number
  }) => void
  animal: AnimalDto | undefined
  setAnimal: React.Dispatch<React.SetStateAction<AnimalDto | undefined>>
  adopter: AdopterDto | null
  close: () => void
}

export const AddDocumentsStep: React.FC<Props> = ({ adopter, animal, submitStep, close, setAnimal }) => {
  const [loading, setLoading] = useState(false)
  const [customer, setCustomer] = useState<CustomerDto | null>(null)
  const [step, setStep] = useState(1)
  const isLocked = true
  const [missingData, setMissingData] = useState<string[]>([])
  const [allVariables, setAllVariables] = useState<VariableFormType | null>()
  const [filesUrls, setFilesUrls] = useState<
    { certificate?: string | undefined; contract?: string | undefined } | undefined
  >()

  const sxField = { ...spacingItem }

  const accountsStore = useAccountsStore()

  const resetDialog = () => {
    setStep(1)
    setFilesUrls(undefined)
    setMissingData([])
    setAllVariables(null)
    setFiles([null, null])
    setLoading(false)
  }

  const [files, setFiles] = useState<(File | null)[]>([null, null]) // Slot 0 and Slot 1
  const [displayError, setDisplayError] = useState(false)

  // Custom function to handle file placement in a specific slot
  const handleDrop = useCallback(
    (acceptedFiles: File[], slot: number) => {
      const updatedFiles = [...files]
      // Place the file into the specified slot
      if (acceptedFiles.length > 0) {
        updatedFiles[slot] = acceptedFiles[0] // Replace file in the specific slot
      }

      setFiles(updatedFiles) // Update state
    },
    [files]
  )

  // Dropzone for slot 0 (Image files)
  const dropzoneForCertificate = useDropzone({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 0), // Handle drop for slot 0
    accept: {
      'application/pdf': ['.pdf'],
    },
  })

  // Dropzone for slot 1 (PDF files)
  const dropzoneForContract = useDropzone({
    onDrop: (acceptedFiles) => handleDrop(acceptedFiles, 1), // Handle drop for slot 1
    accept: {
      'application/pdf': ['.pdf'],
    },
  })

  interface formType {
    certificateKey: string
    contractKey: string
  }

  useEffect(() => {
    const getCustomer = async () => {
      setLoading(true)
      const customer = await CustomersClient.getCurrentCustomer()
      setCustomer(customer)
      setLoading(false)
    }
    getCustomer()
  }, [])

  const generateCertificateMutation = useMutation(
    async (animalId: string) => {
      return await AdoptersClient.generateCertificate(animalId)
    },
    {
      onSuccess: (data) => {
        setFiles((prev) => {
          return [blobToFile(data, 'Certificat by Petso.pdf'), prev[1]]
        })
        setFilesUrls((prev: { certificate?: string | undefined; contract?: string | undefined } | undefined) => {
          return { ...prev, certificate: URL.createObjectURL(data) }
        })
      },
    }
  )

  const fillPdfTemplate = async (templateId: string, type: 'certificate' | 'contract') => {
    setLoading(true)
    if (!adopter || !animal) return
    try {
      const config: AxiosRequestConfig = {
        method: 'GET',
        url: `/adopters/generate/pdf-template/${animal.id}?adopterId=${adopter.id}&templateId=${templateId}`,
        responseType: 'arraybuffer', // Expect binary PDF file
      }

      const response = await backendClient(config)

      // Extract metadata from headers
      const metadataHeader = response.headers['x-metadata'] // Adjust for the exact header name
      console.log('Headers', response.headers)
      let metadata: { missingData: string[]; allVariables: VariableFormType } | undefined
      if (metadataHeader) {
        metadata = JSON.parse(Buffer.from(metadataHeader, 'base64').toString('utf-8'))
        console.log('Metadata:', metadata)
      }

      if (!metadata) return

      // Extract metadata details
      const { missingData, allVariables } = metadata

      // Update state
      setAllVariables(allVariables)
      setMissingData(missingData)

      // Convert binary PDF to Blob for rendering or download
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(blob)
      console.log('Generated PDF URL:', pdfUrl)
      if (type === 'certificate') {
        setFiles((prev) => {
          return [blobToFile(blob, 'Certificat by Petso.pdf'), prev[1]]
        })
      } else {
        setFiles((prev) => {
          return [prev[0], blobToFile(blob, 'Contrat by Petso.pdf')]
        })
      }
      setFilesUrls((prev: { certificate?: string | undefined; contract?: string | undefined } | undefined) => {
        return { ...prev, [type]: pdfUrl }
      })
      setLoading(false)

      // Use `pdfUrl` for rendering or downloading
      console.log('Generated PDF URL:', pdfUrl)
    } catch (error) {
      console.error('Error converting HTML to PDF:', error)
      setLoading(false)
    }
  }

  const convertHtmlToPdf = async (templateKey: string, type: 'certificate' | 'contract') => {
    setLoading(true)
    if (!adopter || !animal) return
    try {
      const config: AxiosRequestConfig = {
        method: 'GET',
        url: `/adopters/generate/template/${animal.id}?adopterId=${adopter.id}&templateKey=${templateKey}`,
        responseType: 'json', // Expect JSON response
      }

      const response = await backendClient(config)

      // Access the JSON response directly
      const htmlContent = response.data.htmlContent
      const missingData = response.data.missingData
      const allVariables = response.data.allVariables
      if (typeof allVariables['{{adoption_fee}}'] === 'string') {
        allVariables['{{adoption_fee}}'] = allVariables['{{adoption_fee}}'].replace('€', '').trim()
      }
      setAllVariables((prev) => {
        if (prev) {
          return { ...prev, ...allVariables }
        } else return allVariables
      })
      setMissingData((prev) => {
        return [...prev, ...missingData]
      })

      const pdf = new jsPDF('p', 'pt', 'a4') // Use points (pt) for dimensions

      // Create a container element to inject the HTML content
      const container = document.createElement('div')
      container.style.width = '565px' // Increase width to fill more of the page
      container.style.margin = '0 auto' // Center the content
      container.innerHTML = htmlContent

      // Improve HTML/CSS for PDF rendering
      container.style.fontSize = '10pt' // Slightly larger font size for better readability
      container.style.lineHeight = '1.3' // Adjust line height to prevent overlap
      container.style.overflow = 'hidden' // Prevent overflow
      container.querySelectorAll('ul').forEach((ul) => {
        ul.style.paddingInlineStart = '20px' // Adjust list indentation
      })
      container.querySelectorAll('li').forEach((li) => {
        li.style.paddingLeft = '10px' // Adjust list item indentation
      })

      // Add the HTML content to the PDF
      await pdf.html(container, {
        callback: (doc) => {
          // Get the PDF as a Blob
          const pdfBlob = doc.output('blob')
          if (type === 'certificate') {
            setFiles((prev) => {
              return [blobToFile(pdfBlob, 'Certificat by Petso.pdf'), prev[1]]
            })
          } else {
            setFiles((prev) => {
              return [prev[0], blobToFile(pdfBlob, 'Contrat by Petso.pdf')]
            })
          }
          setFilesUrls((prev: { certificate?: string | undefined; contract?: string | undefined } | undefined) => {
            return { ...prev, [type]: URL.createObjectURL(pdfBlob) }
          })
          setLoading(false)
        },
        margin: [40, 40, 40, 40], // Set margins in points (20pt ~ 7mm)
        autoPaging: 'text', // Adjust paging based on text content
        html2canvas: {
          proxy: 'https://petso-backend.herokuapp.com/v1/proxy/html2canvas', // Use a proxy to avoid CORS issues
          allowTaint: false,
          useCORS: false,
          letterRendering: true,
          logging: false,
          scale: 0.9, // Return to default scale for better content fit
        },
        width: 565, // Adjusted content width
      })
    } catch (error) {
      console.error('Error converting HTML to PDF:', error)
      setLoading(false)
    }
  }

  const onSubmit = async (data: formType) => {
    if (
      data &&
      (data.certificateKey === 'Pas encore de modèle dans votre asso' ||
        data.contractKey === 'Pas encore de modèle dans votre asso')
    )
      return setDisplayError(true)
    if (!adopter || !animal) return
    if (
      (data.certificateKey === 'Importer un document PDF' && !files[0]) ||
      (data.contractKey === 'Importer un document PDF' && !files[1])
    ) {
      setDisplayError(true)
      return
    }
    setDisplayError(false)
    setLoading(true)
    if (step === 1) {
      if (data.certificateKey === 'Certificat généré par Petso')
        await generateCertificateMutation.mutateAsync(animal.id)

      if (!files[0] && data.certificateKey !== 'Certificat généré par Petso') {
        const documentKey = customer?.templates?.find((template) => template.name === data.certificateKey)?.documentKey
        if (documentKey) await convertHtmlToPdf(documentKey, 'certificate')
        const certificatePdfTemplate = customer?.pdfTemplates?.find(
          (template) => template.type === 'knowledge-certificate-model' && template.name === data.certificateKey
        )
        if (certificatePdfTemplate) {
          await fillPdfTemplate(certificatePdfTemplate.id, 'certificate')
        }
      }

      if (!files[1] && data.contractKey !== 'Le document a été remis en mains propres') {
        if (data.contractKey === 'Contrat généré par Petso') {
          const documentKey = 'C-1002-H2lmUwJMPpx3otc6zYjlX.html'
          if (documentKey) await convertHtmlToPdf(documentKey, 'contract')
        } else {
          const documentKey = customer?.templates?.find((template) => template.name === data.contractKey)?.documentKey
          if (documentKey) await convertHtmlToPdf(documentKey, 'contract')
          const contractPdfTemplate = customer?.pdfTemplates?.find(
            (template) => template.type === 'adoption-contract-model' && template.name === data.contractKey
          )
          if (contractPdfTemplate) {
            await fillPdfTemplate(contractPdfTemplate.id, 'contract')
          }
        }
      }

      if (
        data.certificateKey === 'Le document a été remis en mains propres' &&
        data.contractKey === 'Le document a été remis en mains propres' &&
        animal.adoption.priceInEuros &&
        animal.adoption.priceInEuros > 0
      ) {
        submitStep({ animalId: animal.id, files: files as File[], animalPrice: animal.adoption.priceInEuros })
        return setLoading(false)
      }

      setStep(2)
      return setLoading(false)
    }
    if (step === 2) {
      const certificatePdfTemplate = customer?.pdfTemplates?.find(
        (template) => template.type === 'knowledge-certificate-model' && template.name === certificateKey
      )
      const contractPdfTemplate = customer?.pdfTemplates?.find(
        (template) => template.type === 'adoption-contract-model' && template.name === contractKey
      )
      submitStep({
        animalId: animal.id,
        files: files as File[],
        deliverLater: contractKey === 'A envoyer plus tard',
        isLocked: isLocked,
        certificateTemplateKey: certificatePdfTemplate?.documentKey,
        contractTemplateKey: contractPdfTemplate?.documentKey,
        animalPrice: animal.adoption.priceInEuros,
      })
    }
    setLoading(false)
  }

  const onSubmitVariableForm = async (data: VariableFormType) => {
    if (!adopter || !animal) return
    setLoading(true)

    const newAdopter: AdopterDto = {
      ...adopter,
      firstName: data['{{adopter_first_name}}'] || adopter.firstName,
      lastName: data['{{adopter_last_name}}'] || adopter.lastName,
      address: data['{{adopter_address}}'] || '',
      postalCode: data['{{adopter_postal_code}}'] || '',
      city: data['{{adopter_city}}'] || adopter.city,
      email: data['{{adopter_email}}'] || adopter.email,
      phoneNumber: data['{{adopter_phone}}'] || adopter.phoneNumber,
    }

    const newAnimal: AnimalDto = {
      ...animal,
      name: data['{{animal_name}}'] || animal.name,
      sex: data['{{animal_gender}}'] as AnimalDtoSex,
      birthday: { date: data['{{animal_birth_date}}'] || animal.birthday?.date || '' },
      identificationNumber: data['{{animal_identification_number}}'] || animal.identificationNumber,
      species: (data['{{animal_species}}'] as AnimalDtoSpecies) || animal.species,
      breedData: {
        ...animal.breedData,
        breedTitle: animal.breedData?.breedTitle || 'breed-type',
        breed: data['{{animal_breed}}'] || animal.breedData?.breed || '',
      },
      adoption: {
        ...animal.adoption,
        futureAdoptionDate: data['{{adoption_date}}'] || '',
        priceInEuros: Number(data['{{adoption_fee}}']?.trim().replace(',', '.').replace('€', '')) || 0,
        offerType: data['{{adoption_type}}'] as AnimalAdoptionDtoOfferType,
        newName: data['{{animal_new_name}}'] || '',
        adopterId: adopter.id,
        status: 'in-the-process-of-being-adoptable',
      },
      vaccination: {
        ...animal.vaccination,
        vaccinationStatus: data['{{vaccine_status}}'] as CreateAnimalBodyDtoVaccinationStatus,
      },
      identifyingMarks: data['{{identifying_marks}}']?.split(',').map((mark) => mark.trim()) || [],
    }

    setMissingData([])
    setAllVariables(null)
    resetVariableForm({
      '{{adopter_address}}': adopter?.address || undefined,
      '{{adopter_city}}': adopter?.city || undefined,
      '{{adopter_email}}': adopter?.email || undefined,
      '{{adopter_first_name}}': adopter?.firstName || undefined,
      '{{adopter_last_name}}': adopter?.lastName || undefined,
      '{{adopter_phone}}': adopter?.phoneNumber || undefined,
      '{{adopter_postal_code}}': adopter?.postalCode || undefined,
      '{{adoption_date}}': animal?.adoption?.futureAdoptionDate || undefined,
      '{{adoption_fee}}': animal?.adoption?.priceInEuros?.toString() || undefined,
      '{{adoption_type}}': animal?.adoption?.offerType || undefined,
      '{{animal_birth_date}}': animal?.birthday?.date || undefined,
      '{{animal_breed}}': animal?.breedData?.breed || undefined,
      '{{animal_gender}}': animal?.sex || undefined,
      '{{animal_identification_number}}': animal?.identificationNumber || undefined,
      '{{animal_name}}': animal?.name || undefined,
      '{{animal_new_name}}': animal?.adoption?.newName || undefined,
      '{{current_date}}': undefined,
      '{{vaccine_recall_date}}': undefined,
      '{{vaccine_status}}': animal?.vaccination?.vaccinationStatus || undefined,
      '{{sterilization_date}}': undefined,
      '{{deworming_recall_date}}': undefined,
      '{{flea_control_recall_date}}': undefined,
      '{{identifying_marks}}': animal?.identifyingMarks?.join(', ') || undefined,
      '{{animal_species}}': animal?.species || undefined,
      '{{asso_name}}': undefined,
      '{{asso_address}}': undefined,
      '{{asso_postal_code}}': undefined,
      '{{asso_city}}': undefined,
      '{{asso_siret_number}}': undefined,
      '{{president_last_name}}': undefined,
      '{{president_first_name}}': undefined,
      '{{asso_logo}}': undefined,
      '{{asso_signature}}': undefined,
    })

    const certificatePdfTemplate = customer?.pdfTemplates?.find(
      (template) => template.type === 'knowledge-certificate-model' && template.name === certificateKey
    )
    const contractPdfTemplate = customer?.pdfTemplates?.find(
      (template) => template.type === 'adoption-contract-model' && template.name === contractKey
    )

    const certificateDocumentKey = customer?.templates?.find(
      (template) => template.name === certificateKey
    )?.documentKey
    const regenCertificate =
      certificateKey !== 'Importer un document PDF' &&
      certificateKey !== 'Certificat généré par Petso' &&
      certificateKey !== 'Le document a été remis en mains propres'
    const regenContract =
      contractKey !== 'Importer un document PDF' &&
      contractKey !== 'Le document a été remis en mains propres' &&
      contractKey !== 'A envoyer plus tard'
    const contractDocumentKey = customer?.templates?.find((template) => template.name === contractKey)?.documentKey
      ? customer?.templates?.find((template) => template.name === contractKey)?.documentKey
      : contractKey === 'Contrat généré par Petso'
      ? 'C-1002-H2lmUwJMPpx3otc6zYjlX.html'
      : undefined
    setFilesUrls({
      certificate: regenCertificate ? undefined : filesUrls?.certificate,
      contract: regenContract ? undefined : filesUrls?.contract,
    })
    setFiles([regenCertificate ? null : files[0], regenContract ? null : files[1]])

    await AdoptersClient.editAccount(newAdopter)
    const animalToSet = await AnimalsClient.editAnimal(newAnimal)
    setAnimal(animalToSet)
    resetVariableForm({ '{{adoption_fee}}': animalToSet.adoption?.priceInEuros?.toString() || undefined })

    if (regenCertificate && certificateDocumentKey) {
      await convertHtmlToPdf(certificateDocumentKey, 'certificate')
    } else if (regenCertificate && certificatePdfTemplate) {
      await fillPdfTemplate(certificatePdfTemplate.id, 'certificate')
    }
    if (regenContract && contractDocumentKey) {
      await convertHtmlToPdf(contractDocumentKey, 'contract')
    } else if (regenContract && contractPdfTemplate) {
      await fillPdfTemplate(contractPdfTemplate.id, 'contract')
    }
    setLoading(false)
    if (!regenCertificate && !regenContract && data['{{adoption_fee}}'] != null && data['{{adoption_fee}}'] !== '') {
      submitStep({
        animalId: animal.id,
        files: files as File[],
        deliverLater: contractKey === 'A envoyer plus tard',
        isLocked: isLocked,
        certificateTemplateKey: certificatePdfTemplate?.documentKey,
        contractTemplateKey: contractPdfTemplate?.documentKey,
        animalPrice: animalToSet.adoption.priceInEuros,
      })
    }
  }

  const { control, handleSubmit, watch } = useForm<formType>()

  const specieThatHasCertificateGeneration = true

  const optionsForCertificate = [
    {
      title: 'Mains propres',
      options: ['Le document a été remis en mains propres'],
    },
    {
      title: "Modèles PDF de l'asso",
      options: customer?.pdfTemplates
        ? customer?.pdfTemplates
            ?.filter((template) => template.type === 'knowledge-certificate-model')
            .map((template) => template.name)
        : ['Pas encore de modèle PDF dans votre asso'],
    },
    {
      title: "Modèles de l'asso",
      options: customer?.templates
        ? customer?.templates
            ?.filter((template) => template.type === 'knowledge-certificate-model')
            .map((template) => template.name)
        : ['Pas encore de modèle dans votre asso'],
    },
    {
      title: 'Modèle Petso',
      options: specieThatHasCertificateGeneration ? ['Certificat généré par Petso'] : [],
    },
    {
      title: 'Import ponctuel (pdf)',
      options: ['Importer un document PDF'],
    },
  ]

  const optionsForContract = [
    {
      title: 'Méthodes basiques',
      options: ['A envoyer plus tard', 'Le document a été remis en mains propres'],
    },
    {
      title: "Modèles PDF de l'asso",
      options: customer?.pdfTemplates
        ? customer?.pdfTemplates
            ?.filter((template) => template.type === 'adoption-contract-model')
            .map((template) => template.name)
        : ['Pas encore de modèle PDF dans votre asso'],
    },
    {
      title: "Modèles de l'asso",
      options: customer?.templates
        ? customer?.templates
            ?.filter((template) => template.type === 'adoption-contract-model')
            .map((template) => template.name)
        : ['Pas encore de modèle dans votre asso'],
    },
    {
      title: 'Modèle Petso',
      options: ['Contrat généré par Petso'],
    },
    {
      title: 'Import ponctuel (pdf)',
      options: ['Importer un document PDF'],
    },
  ]

  const breedOptions =
    animal!.species === 'cat'
      ? catBreeds.map((breed) => ({ label: breed, value: breed }))
      : dogBreeds.map((breed) => ({ label: breed, value: breed }))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const certificateKey = watch('certificateKey')
  const contractKey = watch('contractKey')

  useEffect(() => {
    const updateFiles = () => {
      if (step !== 1) return
      let certificate: File | null = files[0]
      let contract: File | null = files[1]
      if (certificateKey !== 'Importer un document PDF') {
        certificate = null
      }
      if (contractKey !== 'Importer un document PDF') {
        contract = null
      }
      setFiles([certificate, contract])
    }

    updateFiles()
  }, [certificateKey, contractKey])

  const {
    control: variableControl,
    handleSubmit: handleVariableSubmit,
    reset: resetVariableForm,
    register: registerVariableForm,
    watch: watchVariableForm,
    formState: { isDirty },
  } = useForm<VariableFormType>({
    defaultValues: {
      '{{adopter_address}}': adopter?.address || undefined,
      '{{adopter_city}}': adopter?.city || undefined,
      '{{adopter_email}}': adopter?.email || undefined,
      '{{adopter_first_name}}': adopter?.firstName || undefined,
      '{{adopter_last_name}}': adopter?.lastName || undefined,
      '{{adopter_phone}}': adopter?.phoneNumber || undefined,
      '{{adopter_postal_code}}': adopter?.postalCode || undefined,
      '{{adoption_date}}': animal?.adoption?.futureAdoptionDate || undefined,
      '{{adoption_fee}}': animal?.adoption?.priceInEuros?.toString() || undefined,
      '{{adoption_type}}': animal?.adoption?.offerType || undefined,
      '{{animal_birth_date}}': animal?.birthday?.date || undefined,
      '{{animal_breed}}': animal?.breedData?.breed || undefined,
      '{{animal_gender}}': animal?.sex || undefined,
      '{{animal_identification_number}}': animal?.identificationNumber || undefined,
      '{{animal_name}}': animal?.name || undefined,
      '{{animal_new_name}}': animal?.adoption?.newName || undefined,
      '{{current_date}}': undefined,
      '{{vaccine_recall_date}}': undefined,
      '{{vaccine_status}}': animal?.vaccination?.vaccinationStatus || undefined,
      '{{sterilization_date}}': undefined,
      '{{deworming_recall_date}}': undefined,
      '{{flea_control_recall_date}}': undefined,
      '{{identifying_marks}}': animal?.identifyingMarks?.join(', ') || undefined,
      '{{animal_species}}': animal?.species || undefined,
      '{{asso_name}}': undefined,
      '{{asso_address}}': undefined,
      '{{asso_postal_code}}': undefined,
      '{{asso_city}}': undefined,
      '{{asso_siret_number}}': undefined,
      '{{president_last_name}}': undefined,
      '{{president_first_name}}': undefined,
      '{{asso_logo}}': undefined,
      '{{asso_signature}}': undefined,
    },
  })

  const species = accountsStore.connectedCustomer?.preferences.species

  const adoptionFee = watchVariableForm('{{adoption_fee}}')

  useEffect(() => {
    if (allVariables && Object.keys(allVariables).length > 0) {
      if (!Object.keys(allVariables).includes('{{adoption_fee}}')) {
        return resetVariableForm({
          ...allVariables,
          '{{adoption_fee}}': adoptionFee,
        })
      } else {
        return resetVariableForm({
          ...allVariables,
          '{{adoption_fee}}': allVariables['{{adoption_fee}}']
            ? allVariables['{{adoption_fee}}'].trim().replace(',', '.').replace('€', '')
            : '0',
        })
      }
    }
  }, [allVariables])

  return (
    <>
      <DialogTitle sx={{ fontSize: 24, fontWeight: 700 }}>Préparation des documents</DialogTitle>
      <DialogContent>
        <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={close}>
          <Close sx={{ fontSize: 30 }} />
        </IconButton>
        {loading && (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" padding={3}>
            <Typography variant="h4" component="h2" sx={{ fontSize: '20px', color: blue }}>
              Génération des documents...
            </Typography>
            <CircularProgress sx={{ marginTop: 2 }} />
          </Box>
        )}
        {step === 1 ? (
          <>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>
              {adopter?.firstName} {adopter?.lastName} dispose d’une interface dédiée pour adopter {animal?.name}{' '}
              (consulter et signer les documents et payer les frais si nécessaire)
            </Typography>
            <Box
              sx={{
                padding: 2,
                color: theme.palette.common.black,
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- TODO: Fix template expression
                border: `1px solid ${theme.palette.info.main}`,
                borderRadius: theme.spacing(0.5),
                margin: theme.spacing(1, 0),
                fontSize: theme.typography.body2.fontSize,
                background: setOpacity(theme.palette.info.main, 0.2),
                fontWeight: 700,
                marginBottom: 2,
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
              }}
            >
              <Info sx={{ color: theme.palette.info.main, marginRight: 1 }} />
              <Typography variant="body1">
                <Link to="/association?tab=templates" style={{ color: 'black' }}>
                  Créez vos modèles de documents (certificat, contrat, etc.) pour les générer et les envoyer
                  automatiquement.
                </Link>
              </Typography>
            </Box>
            <form id="templateForm" onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="h4" fontSize="22px" marginBottom={2}>
                Certificat d’engagement et de connaissance
              </Typography>
              <ControlledAutoCompleteWithCategories
                control={control}
                error={undefined}
                label="Choisir un modèle"
                fieldName="certificateKey"
                options={optionsForCertificate.filter((option) => option.options.length > 0)}
                disabled={loading}
                categoryColor={theme.palette.secondary.main}
                sx={{ marginBottom: 2 }}
              />
              {certificateKey === 'Importer un document PDF' && (
                <div
                  {...dropzoneForCertificate.getRootProps()}
                  style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button variant="contained" component="span" sx={{ textTransform: 'none', minWidth: '50%' }}>
                    <input {...dropzoneForCertificate.getInputProps()} />
                    {files[0]?.name
                      ? files[0]?.name
                      : dropzoneForCertificate.isDragActive
                      ? 'Déposez le fichier ici'
                      : 'Sélectionner un fichier ou glisser-déposer ici'}
                  </Button>
                  {displayError && (
                    <FormHelperText sx={{ marginTop: 1, color: 'red' }}>
                      {certificateKey === 'Importer un document PDF' && !files[0]
                        ? 'Veuillez sélectionner un fichier à envoyer'
                        : ''}
                    </FormHelperText>
                  )}
                </div>
              )}
              {displayError && (
                <FormHelperText sx={{ marginTop: 1, color: 'red' }}>
                  {certificateKey === 'Pas encore de modèle dans votre asso'
                    ? 'Veuillez sélectionner un modèle valide'
                    : ''}
                </FormHelperText>
              )}
              <Typography variant="h4" fontSize="22px" marginBottom={2}>
                Contrat d’adoption
              </Typography>
              <ControlledAutoCompleteWithCategories
                control={control}
                error={undefined}
                label="Choisir un modèle"
                fieldName="contractKey"
                options={optionsForContract.filter((option) => option.options.length > 0)}
                disabled={loading}
                sx={{ marginBottom: 2 }}
                categoryColor={theme.palette.secondary.main}
              />
              {contractKey === 'Importer un document PDF' && (
                <div
                  {...dropzoneForContract.getRootProps()}
                  style={{
                    marginBottom: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Button variant="contained" component="span" sx={{ textTransform: 'none', minWidth: '50%' }}>
                    <input {...dropzoneForContract.getInputProps()} />
                    {files[1]?.name
                      ? files[1]?.name
                      : dropzoneForContract.isDragActive
                      ? 'Déposez le fichier ici'
                      : 'Sélectionner un fichier ou glisser-déposer ici'}
                  </Button>
                  {displayError && (
                    <FormHelperText sx={{ marginTop: 1, color: 'red' }}>
                      {contractKey === 'Importer un document PDF' && !files[0]
                        ? 'Veuillez sélectionner un fichier à envoyer'
                        : ''}
                    </FormHelperText>
                  )}
                </div>
              )}
              {displayError && (
                <FormHelperText sx={{ marginTop: 1, color: 'red' }}>
                  {certificateKey === 'Pas encore de modèle dans votre asso'
                    ? 'Veuillez sélectionner un modèle valide'
                    : ''}
                </FormHelperText>
              )}
              <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...spacingItem, gap: 0.5 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography fontSize={16} fontWeight={700}>
                    Le contrat sera débloqué automatiquement 8 jours après la réception du certificat signé - vous
                    pouvez le débloquer manuellement avant si nécessaire.
                  </Typography>
                </Box>
              </Box>
            </form>
          </>
        ) : (
          <>
            {missingData.length > 0 && (
              <Box marginBottom={2}>
                <InfoBox
                  content="Un ou plusieurs champs utilisés dans le modèle sont manquants !"
                  messageType="error"
                />
              </Box>
            )}
            {!adoptionFee && (
              <Box marginBottom={2}>
                <InfoBox
                  content="Le montant de l'adoption n'a pas été renseigné, veuillez le renseigner pour continuer."
                  messageType="error"
                />
              </Box>
            )}

            {!loading && (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, justifyContent: 'center', mb: 2 }}>
                {/* Certificate Card */}
                <Card
                  sx={{
                    width: 250,
                    backgroundColor: '#f5f5f5',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <CardActionArea
                    href={filesUrls?.certificate ?? '#'}
                    target="_blank"
                    rel="noreferrer"
                    onClick={!files[0] ? (e) => e.preventDefault() : undefined}
                    sx={{ cursor: !files[0] ? 'not-allowed' : 'pointer' }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        component="p"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: '#007BFF', // Use your custom blue color here
                          fontSize: isSmallScreen ? '16px' : '18px',
                          textAlign: 'center',
                        }}
                      >
                        {files[0]?.name ? 'Aperçu du certificat' : "Pas d'aperçu"}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>

                {/* Contract Card */}
                <Card
                  sx={{
                    width: 250,
                    backgroundColor: '#f5f5f5',
                    borderRadius: '10px',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <CardActionArea
                    href={filesUrls?.contract ?? '#'}
                    target="_blank"
                    rel="noreferrer"
                    onClick={!files[1] ? (e) => e.preventDefault() : undefined}
                    sx={{ cursor: !files[1] ? 'not-allowed' : 'pointer' }}
                  >
                    <CardContent>
                      <Typography
                        variant="h6"
                        component="p"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          color: '#007BFF', // Use your custom blue color here
                          fontSize: isSmallScreen ? '16px' : '18px',
                          textAlign: 'center',
                        }}
                      >
                        {files[1]?.name ? 'Aperçu du contrat' : "Pas d'aperçu"}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Box>
            )}
            {((allVariables && Object.keys(allVariables).length > 0) || !animal?.adoption?.priceInEuros) && (
              <form id="variableForm" onSubmit={handleVariableSubmit(onSubmitVariableForm)}>
                <Box marginBottom={2}>
                  <Typography variant="h4" component="h2" sx={{ fontSize: '18px', color: 'black' }}>
                    {`Variables utilisées dans le modèle:`}
                  </Typography>
                  <TextField
                    id="price-input"
                    label="Montant de l'adoption"
                    inputProps={{
                      step: '0.01',
                      inputMode: 'decimal',
                      pattern: '[0-9]*',
                    }}
                    fullWidth
                    {...registerVariableForm('{{adoption_fee}}', { required: true })}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">€</InputAdornment>,
                    }}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    sx={sxField}
                  />
                  {allVariables &&
                    Object.keys(allVariables).length > 0 &&
                    Object.keys(allVariables).map((key) => {
                      if (key.includes('fee') || key.includes('logo') || key.includes('signature')) {
                        return null
                      }
                      if (key.includes('species')) {
                        return (
                          <ControlledSelectField
                            key={key}
                            sx={spacingItem}
                            control={variableControl}
                            error={undefined}
                            fieldName="{{animal_species}}"
                            label="Espèce"
                            options={
                              species
                                ? Object.values(species).map((status: AnimalDtoSpecies) => ({
                                    label: getReadableSpecies(status, { withEmoji: true }),
                                    value: status,
                                  }))
                                : []
                            }
                            requiredRule="L'espèce est requise."
                            disabled={species?.length === 1}
                          />
                        )
                      }
                      if (key.includes('date')) {
                        return (
                          <ControlledDateField
                            key={key}
                            control={variableControl}
                            validate={(value) => isValidPastDateCoherence(value) || value === null}
                            error={undefined}
                            fieldName={key as keyof VariableFormType}
                            label={translateVariablePlaceholder(key)}
                            requiredRule={undefined}
                            sx={{
                              ...sxField,
                              backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                            }}
                            disabled={key.includes('recall') || key.includes('sterilization')}
                            helperText={
                              key.includes('recall') || key.includes('sterilization')
                                ? `Champ modifiable uniquement via la fiche de l'animal`
                                : ''
                            }
                            size="small"
                          />
                        )
                      }
                      if (key.includes('phone')) {
                        return (
                          <FormControl
                            fullWidth
                            key={key}
                            variant="outlined"
                            sx={{
                              ...sxField,
                            }}
                          >
                            <Controller
                              name={key as keyof VariableFormType}
                              control={variableControl}
                              rules={{
                                validate: (value: string | undefined) => {
                                  return !value ||
                                    value.trim() === '' ||
                                    matchIsValidTel(value) ||
                                    value.trim() === '+33'
                                    ? true
                                    : 'Tel is invalid'
                                },
                              }}
                              render={({ field, fieldState }) => (
                                <MuiTelInput
                                  {...field}
                                  label="Numero de Téléphone"
                                  defaultCountry="FR"
                                  helperText={fieldState.error ? "Le numero de téléphone n'est pas valide" : ''}
                                  error={!!fieldState.error}
                                  sx={{
                                    backgroundColor: missingData.includes(key)
                                      ? setOpacity(colors.red, 0.5)
                                      : 'inherit',
                                  }}
                                  size="small"
                                />
                              )}
                            />
                          </FormControl>
                        )
                      }
                      if (animal && key.includes('breed') && (animal?.species === 'dog' || animal?.species === 'cat')) {
                        return (
                          <ControlledAutoCompleteWithCustomValue
                            key={key}
                            control={variableControl}
                            fieldName={key as keyof VariableFormType}
                            error={undefined}
                            aria-label="Race"
                            label="Type de Race"
                            requiredRule="Le type de race est requis"
                            options={breedOptions}
                            defaultValue=""
                            size="small"
                            sx={{
                              ...sxField,
                              backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                            }}
                          />
                        )
                      }
                      if (key.includes('adoption_type')) {
                        return (
                          <ControlledSelectField
                            key={key}
                            control={variableControl}
                            error={undefined}
                            fieldName={key as keyof VariableFormType}
                            label={translateVariablePlaceholder(key)}
                            options={Object.values(AnimalAdoptionDtoOfferType).map(
                              (field: AnimalAdoptionDtoOfferType) => ({
                                label: getReadableAnimalAdoptionOfferType(field),
                                value: field,
                              })
                            )}
                            requiredRule=""
                            size="small"
                            sx={{
                              ...sxField,
                              backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                            }}
                          />
                        )
                      }
                      if (key.includes('gender')) {
                        return (
                          <ControlledSelectField
                            key={key}
                            sx={{
                              ...sxField,
                              backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                            }}
                            control={variableControl}
                            error={undefined}
                            fieldName={key as keyof VariableFormType}
                            label={translateVariablePlaceholder(key)}
                            options={[
                              { label: 'Mâle', value: 'male' },
                              { label: 'Femelle', value: 'female' },
                            ]}
                            requiredRule="Le sexe est requis"
                            size="small"
                          />
                        )
                      }
                      if (key.includes('vaccine_status')) {
                        return (
                          <ColoredChipSelectField
                            key={key}
                            control={variableControl}
                            error={undefined}
                            fieldName={key as keyof VariableFormType}
                            size="small"
                            label="Statut primovaccination"
                            options={Object.values(CreateAnimalBodyDtoVaccinationStatus).map(
                              (status: CreateAnimalBodyDtoVaccinationStatus) => ({
                                label: getReadableAnimalVaccineStatus(status),
                                value: status,
                                color: getColorForAnimalVaccineStatus(status),
                              })
                            )}
                            sx={{
                              ...sxField,
                              backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                            }}
                            requiredRule="Le statut primovaccination est requis"
                          />
                        )
                      }
                      return (
                        <Controller
                          key={key}
                          name={key as keyof VariableFormType}
                          control={variableControl}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              label={translateVariablePlaceholder(key)}
                              fullWidth
                              sx={{
                                ...sxField,
                                backgroundColor: missingData.includes(key) ? setOpacity(colors.red, 0.5) : 'inherit',
                              }}
                              size="small"
                              disabled={key.includes('asso') || key.includes('president')}
                              helperText={
                                key.includes('asso') || key.includes('president')
                                  ? `Champ modifiable uniquement via la fiche de l'asso`
                                  : ''
                              }
                            />
                          )}
                        />
                      )
                    })}
                </Box>
              </form>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'between' }}>
        {step === 2 ? (
          <LoadingButton variant="outlined" onClick={() => resetDialog()} disabled={loading} loading={loading}>
            Retour
          </LoadingButton>
        ) : (
          <Box />
        )}
        {isDirty && step === 2 ? (
          <LoadingButton
            data-testid="apply-changes"
            variant="contained"
            form="variableForm"
            type="submit"
            loading={loading}
            disabled={loading}
            sx={{
              textTransform: 'none',
              fontSize: { xs: '14px', sm: '16px' },
              width: { xs: '50%', sm: 'auto' },
              px: { xs: 'auto', sm: '16px' },
            }}
          >
            Enregistrer les modifications
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            sx={{
              textTransform: 'none',
              fontSize: { xs: '14px', sm: '16px' },
              width: { xs: '50%', sm: 'auto' },
              px: { xs: 'auto', sm: '16px' },
            }}
            onClick={() => onSubmit({ certificateKey: certificateKey, contractKey: contractKey })}
            disabled={(step === 2 && !adoptionFee) || loading}
            loading={loading}
          >
            {step === 2 ? (missingData && missingData.length > 0 ? 'Valider quand même' : `Valider`) : `Suivant`}
          </LoadingButton>
        )}
      </DialogActions>
    </>
  )
}
