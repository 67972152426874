import { Box, Typography } from '@mui/material'
import React from 'react'
import { colors } from '../../theme'
import image from './images/featureTitleShape.png'

interface Props {
  title: string
  children: React.ReactNode
}

export const FeaturesList: React.FC<Props> = ({ title, children }) => {
  return (
    <Box sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'start', gap: '6px' }}>
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: 18,
          color: colors.blue,
          position: 'relative',
          zIndex: 1,
          ml: '18px',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Typography>
      {children}
      <img
        src={image}
        alt="Abstract form to add nice design"
        width={39}
        height={39}
        style={{ position: 'absolute', top: 0, left: 0, zIndex: 0 }}
      />
    </Box>
  )
}
