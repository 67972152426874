import { Check, Close, ReportGmailerrorredOutlined } from '@mui/icons-material'
import { Avatar, Box, Button, DialogContent, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { backendClient } from '../../../interactors/clients/client'
import { CustomEditor } from '../../Association/CustomEditor'
import { colors } from '../../theme'

interface Props {
  submitStep: (data: {
    animalId?: string
    files?: File[]
    deliverLater?: boolean
    isLocked?: boolean
    certificateTemplateKey?: string
    contractTemplateKey?: string
  }) => void
  hasAnimal: boolean
  close: () => void
  paymentLink?: string
  price?: number
  adopterFirstName: string
  adopterEmail: string
  animalName: string
  customerName: string
  animalId: string
  adopterId: string
  subscribed?: boolean
}

export const SummaryStep: React.FC<Props> = ({
  submitStep,
  hasAnimal,
  close,
  animalId,
  adopterId,
  animalName,
  customerName,
  subscribed = false,
}) => {
  const [editorContent, setEditorContent] = useState<string>('')
  const [subject, setSubject] = useState<string>(`${customerName} - Votre adoption de ${animalName}`)

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const email = await backendClient.post('adopters/generate-email', {
          animalId,
          adopterId,
        })
        setEditorContent(email.data)
      } catch (error) {
        console.error('Error fetching email:', error)
      }
    }

    fetchEmail()
  }, [])

  const sendEmailAndSubmit = async () => {
    try {
      await backendClient.post('adopters/send-email', {
        adopterId,
        email: editorContent,
        subject: subject,
      })
      submitStep({})
    } catch (error) {
      console.error('Error sending email:', error)
    }
  }
  return (
    <DialogContent>
      {subscribed && (
        <IconButton sx={{ position: 'absolute', top: 10, right: 10 }} onClick={close}>
          <Close />
        </IconButton>
      )}

      {hasAnimal ? (
        <Box sx={{ textAlign: 'center', padding: '1.5rem' }}>
          <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center', mb: 1, textAlign: 'left' }}>
            <ReportGmailerrorredOutlined sx={{ color: colors.cello, fontSize: '3rem' }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 2 }}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold',
                  color: colors.cello,
                }}
              >
                Procédure d’adoption créée
              </Typography>
            </Box>
          </Box>
          <TextField
            fullWidth
            label="Sujet de l'email"
            type="email"
            value={subject}
            size="small"
            onChange={(e) => setSubject(e.target.value)}
            sx={{ my: 1 }}
          />
          <CustomEditor editorContent={editorContent} setEditorContent={setEditorContent} logoUrl="" signatureUrl="" />

          {/* Buttons */}
          <Box sx={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            {subscribed && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => submitStep({})}
                sx={{ fontWeight: 'bold', mt: 1, mr: 1 }}
              >
                Ne pas envoyer de mail
              </Button>
            )}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => sendEmailAndSubmit()}
              sx={{
                fontWeight: 'bold',
                mt: 1,
                backgroundColor: colors.cello,
              }}
            >
              Envoyer l&apos;email et accéder à la procédure
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <Avatar sx={{ height: '80px', width: '80px', backgroundColor: colors.lightGreen }}>
            <Check />
          </Avatar>
          <Typography variant="h6">L’adoptant a bien été créé</Typography>
          <Button variant="contained" color="primary" onClick={() => submitStep({})}>
            Aller voir
          </Button>
        </Box>
      )}
    </DialogContent>
  )
}
