import { LoadingButton } from '@mui/lab'
import { Box, Card, CircularProgress, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { isAdmin } from '../../../domain/Account/isAdmin'
import { backendClient } from '../../../interactors/clients/client'
import { CustomersClient } from '../../../interactors/clients/CustomerClient'
import { CustomerDto } from '../../../interactors/gen/backendClient'
import { useAccountsStore } from '../../../store/AccountsStore'
import { useGlobalSnackbarStore } from '../../../store/GlobalSnackBarStore'
import { colors, spacingItem, theme } from '../../theme'
import { ChoosePaymentPlanModal } from './ChoosePaymentPlanModal'
import OfferTabs from './OfferTabs'

interface RenewSubscriptionData {
  stripeCustomerId: string
}

interface PortalSessionData {
  customerId: string
}

export const FunctionalitiesTab = () => {
  /* const accountsStore = useAccountsStore()
  const membersCount = accountsStore.members.filter(
    (member) => member.permission !== AccountDtoPermission.Silent
  ).length */
  const globalSnackbarStore = useGlobalSnackbarStore()
  const queryParams = new URLSearchParams(window.location.search)
  const [customer, setCustomer] = useState<CustomerDto | null>(null)

  const accountsStore = useAccountsStore()
  const currentUserIsAdmin = accountsStore.connectedAccount ? isAdmin(accountsStore.connectedAccount) : false
  const renewMutation = useMutation(
    (data: RenewSubscriptionData) => backendClient.post('/stripe/reactivate-subscription', data),
    {
      onSuccess: () => {
        globalSnackbarStore.triggerSuccessMessage('Votre abonnement a bien été renouvelé')
      },
      onError: (error) => {
        // Handle error.
        console.error('Error Creating Checkout Session:', error)
      },
    }
  )

  const dashboardMutation = useMutation(
    (data: PortalSessionData) => backendClient.post('/stripe/create-portal-session-customer-id', data),
    {
      onSuccess: (data) => {
        window.location.href = data.data
      },
      onError: (error) => {
        // Handle error.
        console.error('Error Creating Portal Session:', error)
      },
    }
  )

  const handleClick = async () => {
    if (hasCanceledSubscription && customer?.stripeCustomerId) {
      try {
        const { data: session } = await renewMutation.mutateAsync({ stripeCustomerId: customer.stripeCustomerId })
        console.log('Subscription renewed:', session)
      } catch (error) {
        // Handle error
        console.error('Error Renewing Subscription:', error)
      }
    } else if (isSubscribed) {
      const customerId = customer?.stripeCustomerId || ''

      try {
        const { data: session } = await dashboardMutation.mutateAsync({ customerId })
        console.log('Portal Session Created:', session)
      } catch (error) {
        // Handle error
        console.error('Error Creating Portal Session:', error)
      }
    }
  }

  const isSubscribed = customer?.subscription?.status === 'active'

  const hasCanceledSubscription = customer?.subscription?.status === 'canceled'

  const maxMembers = 10

  const fetchCustomerMutation = useMutation(async () => await CustomersClient.getCurrentCustomer(), {
    onSuccess: (data) => {
      setCustomer(data || null)
    },
  })

  useEffect(() => {
    fetchCustomerMutation.mutate()
  }, [])

  useEffect(() => {
    if (queryParams.get('success')) {
      globalSnackbarStore.triggerSuccessMessage('Félicitations ! Vous êtes maintenant abonné à Petso')
    } else if (queryParams.get('canceled')) {
      globalSnackbarStore.triggerErrorMessage('Votre paiement a échoué. Veuillez rééssayer.')
    }
  }, [])

  if (!customer) {
    return <CircularProgress />
  }

  return (
    <>
      <Box sx={{ padding: 2, backgroundColor: 'grey.100', minHeight: '100%' }}>
        <Box sx={{ maxWidth: '1200px', marginX: 'auto', marginY: 2 }}>
          <Box
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: 'white',
              fontSize: '22px',
              fontWeight: 700,
              width: 'fit-content',
              p: 1,
              py: 0.5,
              pl: 2,
              pr: 10,
              mb: 4,
              borderRadius: '11px',
              transform: 'rotate(-1deg)',
            }}
          >
            Abonnement
          </Box>
          <Box sx={{ maxWidth: '1050px', marginX: 'auto' }}>
            <Card
              sx={{
                borderRadius: 3,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: { md: 'row', xs: 'column' },
                justifyContent: 'space-between',
                padding: 1.5,
                px: 4,
                gap: 2,
                alignItems: 'center',
                backgroundColor: '#F7F7F9',
              }}
            >
              <Box sx={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: isSubscribed ? theme.palette.primary.main : theme.palette.success.main,
                    fontSize: 24,
                    fontWeight: 'bold',
                  }}
                >
                  Vous bénéficier de <br />
                  l&apos;offre {isSubscribed ? 'Utile' : 'gratuite'}
                </Typography>
                {!isSubscribed && (
                  <Typography variant="subtitle1" sx={{ color: colors.blueSky, fontSize: 14 }}>
                    Vous êtes limité à {maxMembers} licences
                  </Typography>
                )}

                {/* <Box>
                  <LinearProgress
                    variant="determinate"
                    value={(2 / 3) * 100}
                    sx={{
                      height: 30,
                      borderRadius: 5,
                      backgroundColor: '#e0e0e0',
                      '& .MuiLinearProgress-bar': {
                        borderRadius: '5px 0 0 5px',
                        backgroundColor: theme.palette.success.main,
                      },
                    }}
                  />
                </Box>
                <Typography sx={{ color: colors.cello, fontSize: 14, textAlign: 'center' }}>
                  2/3 frais d’adoption en ligne via Petso ce mois <br />
                  ou <span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Payer 6 euros par crédit</span>
                </Typography>*/}
              </Box>

              <Box
                sx={{
                  flex: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: 'black',
                    fontSize: 13,
                  }}
                >
                  <b>
                    Depuis 2 ans, notre outil propose une version gratuite pour aussi soutenir les petites associations
                    et rester accessible au plus grand nombre.
                  </b>
                </Typography>
                {isSubscribed ? (
                  <Tooltip title={!currentUserIsAdmin ? "Seuls les administrateurs peuvent gérer l'abonnement" : ''}>
                    <span>
                      <LoadingButton
                        onClick={() => handleClick()}
                        variant="contained"
                        loading={renewMutation.isLoading || dashboardMutation.isLoading}
                        sx={{
                          ...spacingItem,
                          fontWeight: 400,
                          fontSize: 20,
                          color: 'white',
                          width: '100%',
                          fontFamily: 'Insaniburger',
                        }}
                        disabled={!currentUserIsAdmin}
                      >
                        {hasCanceledSubscription ? 'Renouveler mon offre' : 'Gérer mon abonnement'}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                ) : (
                  <Box sx={{ mt: 2, display: 'flex', width: '100%', justifyContent: 'center' }}>
                    <ChoosePaymentPlanModal
                      offerName="utile"
                      prices={['48', '492']}
                      priceIds={['price_1O4JKCKwIFnHkxNPL6rDRzL5', 'price_1O4JJlKwIFnHkxNPQY9EHNDk']}
                      disabled={!currentUserIsAdmin}
                    />
                  </Box>
                )}
              </Box>
            </Card>
            <OfferTabs isSubscribed={isSubscribed} />
          </Box>
        </Box>
      </Box>
    </>
  )
}
